import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';

const AddPropertyTestimonials = () => {
  const { propertyId } = useParams(); 
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    short_desc: '',
    name: '', 
    star_rating: '',
    email: '', 
    user_img: null, 
    property_id: propertyId,
  });

  const getUserToken = () => localStorage.getItem('token');

  const isLoggedIn = true; 
  
  useEffect(() => {
  const isLoggedIn = true; // Replace this with your actual login check logic

  if (!isLoggedIn) {
    // Redirect to login if not logged in
    navigate('/Login');
  } else {
    // Load user token from local storage and set it in axios headers
    const token = getUserToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}, [navigate]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({ ...prevState, user_img: e.target.files[0] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({ ...prevState, property_id: propertyId }));

    // Assuming you have a function to get the user token from local storage
    const getUserToken = () => localStorage.getItem('token');
    const token = getUserToken();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

  // Make the POST request to the backend API
  axios
    .post('https://dayoffice24.it/backend/api/property-review/store', formDataToSend, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // Make sure to set the content type for FormData
      },
    })
    .then((response) => {
      if (response.data.success) {
        console.log('Testimonial submitted successfully!');
        setSuccessMessage('Testimonial submitted successfully!');
        // Redirect to a success page or navigate to another route if needed
       navigate(`/PropertyView/${propertyId}`); // Replace '/Home' with the desired success route
      } else {
        console.error('Impossibile inviare la testimonianza:', response.data.message);
        setErrorMessage(response.data.message); // Use the error message from the API response
        setSuccessMessage(''); // Clear the success message
      }
    })
    .catch((error) => {
      console.error('Error submitting testimonial:', error);
      setErrorMessage('Errore durante linvio della testimonianza. Per favore riprova.');
      setSuccessMessage(''); // Clear the success message
    });
};

  return (
    <div className="main_wrap">
      <Header />
      <div className="content-wrapper">
        <section className="section_padding pb-75 pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="tou_booking_form_Wrapper">
                  <div className="booking_tour_form">
                    <h3 className="heading_themed text-center mb-30">
                     Aggiungi la tua recensione sulla proprietà
                    </h3>
                    <div className="tour_booking_form_box property-desc"> 
                      <form className="review-form" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <p>Le tue valutazioni per questa proprietà</p>
                              <div className="star-rating">
                                <input
                                  id="star-5"
                                  type="radio"
                                  name="star_rating"
                                  value="5"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-5" title="5 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 5 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-4"
                                  type="radio"
                                  name="star_rating"
                                  value="4"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-4" title="4 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 4 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-3"
                                  type="radio"
                                  name="star_rating"
                                  value="3"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-3" title="3 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 3? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-2"
                                  type="radio"
                                  name="star_rating"
                                  value="2"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-2" title="2 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 2 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-1"
                                  type="radio"
                                  name="star_rating"
                                  value="1"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-1" title="1 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 1 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                id="msg"
                                cols="30"
                                rows="6"
                                placeholder="Scrivere una recensione"
                                name="review"
                                value={formData.review}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </div>
                          <input type="hidden" name="property_id" value={propertyId} />
                          <div className="col-lg-12">
                            <button type="submit" className="btn style2">
                             invia recensione
                            </button>
                          </div>
                          {errorMessage && (
                            <div className="col-lg-12 mt-3">
                              <div className="alert alert-danger" role="alert">
                                {errorMessage}
                              </div>
                            </div>
                          )}
                          {successMessage && (
                            <div className="col-lg-12 mt-3">
                              <div className="alert alert-success" role="alert">
                                {successMessage}
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AddPropertyTestimonials;