import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const Terms = () => {
  const [termsContent, setTermsContent] = useState('');

  useEffect(() => {
    // Fetch data from the API
    fetch('https://dayoffice24.it/backend/api/terms-and-condition')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Log the API response to check its format
        if (data.success && data.data && data.data.length > 0) {
          setTermsContent(data.data[0].description);
        } else {
          throw new Error('API returned an error: No terms and conditions data found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching terms and conditions data:', error);
      });
  }, []);


  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });

  useEffect(() => {
  fetch('https://dayoffice24.it/backend/api/seo-details/terms')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching SEO data:', error);
    });
}, []);


  

  return (
    <div className="main_wrap">

     <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
     </Helmet>


      <Header />

      <div className="content-wrapper">
        <div class="breadcrumb-wrap bg-f br-2">
          <div class="container">
            <div class="breadcrumb-title text-center">
              <h2>Termini e Condizioni</h2>
              <ul class="breadcrumb-menu list-style">
                <li>
                  <Link to="/">Casa</Link>
                </li>
                <li>Termini e Condizioni</li>
              </ul>
            </div>
          </div>
        </div>

        <section class="terms-wrap pt-100 pb-75 mb-10">
          <div class="container">
            <div class="row gx-5">
              <div class="col-lg-12">
                {termsContent && (
                  <div dangerouslySetInnerHTML={{ __html: termsContent }} />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
