import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Axios from 'axios';
import { Helmet } from 'react-helmet';

const Register = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });


  useEffect(() => {
    fetchSeoDetails();
  }, []);

  const fetchSeoDetails = async () => {
    try {
      const response = await fetch('https://dayoffice24.it/backend/api/seo-details/register');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  };


const handleRegister = async (event) => {
  event.preventDefault();

  if (!username || !name || !email || !password) {
    setError('Si prega di compilare tutti i campi richiesti.');
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setError('Si prega di inserire un indirizzo email valido.');
    return;
  }

  const termsCheckbox = document.getElementById('terms');
  if (!termsCheckbox.checked) {
    setError('Si prega di accettare i termini e le condizioni.');
    return;
  }

  try {
    const response = await Axios.post('https://dayoffice24.it/backend/api/register', {
      name: name,
      email: email,
      password: password,
      assign_role: 'visitor',
      username: username,
    });

    if (response.data.success) {
      setError('');
      setSuccessMessage('Registrazione completata – Controlla la posta'); // Updated success message
      // Clear form fields after successful registration
      setUsername('');
      setFullName('');
      setEmail('');
      setPassword('');

      // Use setTimeout to add a 0.5-second delay before redirecting
      setTimeout(() => {
        navigate('/Login'); // Redirect to the Login page
      }, 4000);
    } else {
      setError(response.data.message); // Set the error message from the API response
      setSuccessMessage('');
    }
  } catch (error) {
    setError('Si è verificato un errore. Per favore riprova più tardi.');
    setSuccessMessage('');
  }
};

  return (
    <div className="main_wrap">

     <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
     </Helmet>

      <Header />
      <div className="content-wrapper">
        <section className="property-details-wrap pt-75 pb-75">
          <div className="container">
            <div className="row align-items-center login_bg gx-5">
              <div className="col-md-6">
                <div className="login-bg bg-f"></div>
              </div>
              <div className="col-md-6">
                <div className="login-content">
                  <h2>Iscrizione</h2>
                  <p>Compila il modulo per creare un account</p>
                  <form className="user-form" onSubmit={handleRegister} action="#">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="username"
                            name="username"
                            type="text"
                            placeholder="Nome utente"
                            required=""
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="fname"
                            name="name"
                            type="text"
                            placeholder="Nome e cognome"
                            required=""
                            value={name}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            required=""
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="pwd_2"
                            name="pwd_2"
                            type="password"
                            placeholder="Password"
                            required=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input type="hidden" name="assign_role" value="visitor" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-12">
                       <div className="checkbox style3">
                         <input type="checkbox" id="terms" />
                         <label htmlFor="terms">
                         Sono d'accordo con i 
                           {' '}
                           <a target="_blank" className="link style1" href="/Terms">
                             Termini e condizioni.
                           </a>{' '}
                           E{' '}
                           <a target="_blank" className="link style1" href="/Privacy">
                             politica sulla riservatezza
                           </a>
                         </label>
                       </div>
                     </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button className="btn style1">Iscriviti ora</button>
                        </div>
                      </div>
                      {error && (
                        <div className="col-lg-12">
                          <p className="text-danger">{error}</p>
                        </div>
                      )}
                      {successMessage && (
                        <div className="col-lg-12">
                          <p className="text-success">{successMessage}</p>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
