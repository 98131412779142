import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const NotFound = () => {

  return (
    <div className="main_wrap">

     <Helmet>
      <title>Non trovato - HRD Group Srl S.</title>
      <meta name="description" content="Non trovato" />
      <meta name="keywords" content="Non trovato" />
     </Helmet>

      <Header />

      <div className="content-wrapper">
        <div className="breadcrumb-wrap bg-f br-2">
          <div className="container">
            <div className="breadcrumb-title text-center">
              <h2>Non trovato</h2>
              <ul className="breadcrumb-menu list-style">
                <li>
                 <Link to="/">Casa</Link>
                </li>
                <li>Non trovato</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="terms-wrap pt-100 pb-75 mb-10">
          <div className="container">
            <div className="row gx-5 text-center">
                <h2>404 - Not Found</h2>
                <p>The page you are looking for does not exist.</p>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default NotFound;
