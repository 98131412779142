import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import listing_shape from '../assets/images/add-listing-shape.png';
import city_shape from '../assets/images/shape-2_1.png';
import ProductCarousel from '../components/ProductCarousel';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CallAction from '../components/CallAction';
import { Helmet } from 'react-helmet';


const Home = () => {

  const [backgroundImage, setBackgroundImage] = useState(''); 
  const [h1Title, setH1Title] = useState('');
  const [propertyData, setPropertyData] = useState([]);
  const [sectionHeading, setSectionHeading] = useState('');
  const [sectionParagraph, setSectionParagraph] = useState('');
  const [aboutData, setAboutData] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [sectionData, setSectionData] = useState(null);
  const [sectionDatatest, setSectionDatatest] = useState(null);
  const [searchFormData, setSearchFormData] = useState({
    search_input: '',
    start_date: '',
    end_date: '',
    no_of_guest: '0',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchFormData({
      ...searchFormData,
      [name]: value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    window.location.href = `/PropertySearch?search_input=${searchFormData.search_input}&start_date=${searchFormData.start_date}&end_date=${searchFormData.end_date}&no_of_guest=${searchFormData.no_of_guest}`;
  };



  useEffect(() => {
    // Fetch data from the API
    fetch('https://dayoffice24.it/backend/api/section/1')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); 
        if (data.success) {
          setBackgroundImage(data.data.photo);
          setH1Title(data.data.title);
        } else {
          throw new Error('API returned an error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching section data:', error);
      });

       // Fetch property data from the API
    fetch('https://dayoffice24.it/backend/api/recommended-property')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Log the API response to check its format
        if (data.success) {
          setPropertyData(data.data); // Assuming the property data is an array of objects
        } else {
          throw new Error('API returned an error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching property data:', error);
      });

     // Fetch data from the API
    fetch('https://dayoffice24.it/backend/api/section/2')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Log the API response to check its format
        if (data.success) {
          setSectionHeading(data.data.title);
          setSectionParagraph(data.data.short_desc);
        } else {
          throw new Error('API returned an error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching section data:', error);
      });
          fetch('https://dayoffice24.it/backend/api/section/3')
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setAboutData(data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching about data:', error);
      });

    // Fetch city data from the API
    fetch('https://dayoffice24.it/backend/api/city-section')
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setCityData(data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching city data:', error);
      });

    // Fetch top heading and subheading data from the API
    fetch('https://dayoffice24.it/backend/api/section/4')
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setSectionData(data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching section data:', error);
    });

    // Fetch section data from the API
    fetch('https://dayoffice24.it/backend/api/section/5')
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setSectionDatatest(data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching section data:', error);
      });

  }, []);


  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });

  useEffect(() => {
  fetch('https://dayoffice24.it/backend/api/seo-details/home')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching SEO data:', error);
    });
}, []);


  return (
     
   <div className="main_wrap">
    <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
    </Helmet>


     <Header />

     <section className="hero-wrap style2 border_remove_hero"> 
         <div className="container-fluid14">
            <div className="hero-slider-one">
               <div className="hero-slide-item bg-f" style={{ backgroundImage: `url(${backgroundImage})` }}>
                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-10">
                           <div className="hero-content text-center">
                              <h1>{h1Title}</h1>
                           </div>
                        </div>
                     </div>                     
                     <div className="row justify-content-center">
                          <div className="col-lg-10 col-md-8 col-sm-10">
                            <div className="search-property style1" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="250">
                              <form onSubmit={handleFormSubmit} className="property-search-form">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="search_input" required
                                    value={searchFormData.search_input}
                                    onChange={handleInputChange}
                                    placeholder="Dove vuoi andare?"
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="date"
                                    name="start_date" required
                                    value={searchFormData.start_date}
                                    onChange={handleInputChange}
                                    className="custom-date-input"
                                    placeholder="Registrare"  min={new Date().toISOString().split('T')[0]} 
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="date"
                                    name="end_date" required
                                    value={searchFormData.end_date}
                                    onChange={handleInputChange}
                                    className="custom-date-input"
                                    placeholder="Guardare" min={new Date().toISOString().split('T')[0]} 
                                  />
                                </div>
                                <div className="form-group">
                                  <select name="no_of_guest" id="guestCount" value={searchFormData.no_of_guest} onChange={handleInputChange}>
                                    <option value="0" data-display="Price Range">
                                     Numero Ospiti
                                    </option>
                                    <option value="1">1 Ospite</option>
                                    <option value="2">2 Ospite</option>
                                    <option value="3">3 Ospite</option>
                                    <option value="4">4 Ospite</option>
                                    <option value="5">5 Ospite</option>
                                    <option value="6">6 Ospite</option>
                                    <option value="7">7+ Ospite</option>
                                  </select>
                                </div>
                                <div className="form-group">
                                  <button type="submit" className="btn style2">
                                    Cerca DayOffice
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                  </div>
               </div>
            </div>
         </div>
      </section>

      <section className="property-wrap ptb-100">
         <div className="container">
            <div className="row">
               <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                  <div className="section-title style1 text-center mb-40">
                      <h2>{sectionHeading}</h2>
                     <p>{sectionParagraph}</p>
                  </div>
               </div>
            </div>
            <div className="property-slider-two owl-carousel slide">                 
               <ProductCarousel propertyData={propertyData} /> 
            </div>
         </div>
      </section>

      <section className="about-wrap gray_bg_updated style1 ptb-100 bg-seashell">
        <div className="container">
          {aboutData && (
            <div className="row gx-5 align-items-center bg-white_image">
              <div className="col-lg-8" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                <div className="about_img">
                  <img src={aboutData.photo} alt="About" />
                </div>
              </div>
              <div className="col-lg-4" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">
                <div className="about-content">
                  <div className="content-title style1">
                    <h2>{aboutData.title}</h2>
                    <p>{aboutData.short_desc}</p>
                  </div>
                  <Link to="/BecomeHost" className="btn style2">
                      Diventa Host
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="city-wrap pt-100 pb-75 bg-seashell">
      <img className="city-shape-one" src={city_shape} alt="Image" />
      <div className="container">
        {sectionData && (
          <div className="section-title style1 text-center mb-40">
            <h2>{sectionData.title}</h2>
            <p>{sectionData.short_desc}</p>
          </div>
        )}
        <div className="row justify-content-center">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
            {cityData.slice(0, 1).map((city) => (
              <div key={city._id} className="city-card first_city_name style1" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                <a href={`/Cities/${city._id}`}><img src={city.city_img} alt="Image" /></a>
                <div className="city-info">
                  <h3><a href={`/Cities/${city._id}`}>{city.city_name}</a></h3>
                  <p>+{city.total_properties} proprietà</p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-4 explore_right">
            {cityData.slice(1, 3).map((city) => (
              <div key={city._id} className="col-xxl-12 col-xl-12 col-lg-12 col-md-12" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                <div className="city-card style1">
                  <a href={`/Cities/${city._id}`}><img src={city.city_img} alt="Image" /></a>
                  <div className="city-info">
                    <h3><a href={`/Cities/${city._id}`}>{city.city_name}</a></h3>
                    <p>+{city.total_properties} proprietà</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

   <section className="testimonial-wrap style2 ptb-100 pt-0 bg-seashell">
      <div className="container">
         <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
               {sectionDatatest && (
                  <div className="section-title style1 text-center mb-40">
                    <h2>{sectionDatatest.title}</h2>
                    <p>{sectionDatatest.short_desc}</p>
                  </div>
                )}
            </div>
         </div>
      </div>
      <div className="container-fluid">
         <div className="testimonial-slider-two owl-carousel">
             <TestimonialsCarousel />
             <div className="center_btn">
                  <Link className="btn style1" to="/AddTestimonials">Scrivi una Recensione</Link>
             </div>
         </div>
      </div>
   </section>

    <CallAction />

    <Footer />  

    </div>

  );
};

export default Home;
