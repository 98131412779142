import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CallAction from '../components/CallAction';
import { Helmet } from 'react-helmet';

const HelpCenter = () => {
  const [apiData, setApiData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredApiData, setFilteredApiData] = useState([]);
  const [headingData, setHeadingData] = useState({ heading: '', sub_heading: '' });
  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });

  useEffect(() => {
    // Fetch data from the API for help center items
    fetch('https://dayoffice24.it/backend/api/help-center')
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setApiData(data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data from API:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch data from the API for heading and subheading
    fetch('https://dayoffice24.it/backend/api/help-center-heading')
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setHeadingData({
            heading: data.data.heading,
            sub_heading: data.data.sub_heading,
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching heading data from API:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch SEO details from the API
    fetch('https://dayoffice24.it/backend/api/seo-details/Help')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setSeoDetails({
            title: data.data.page_title,
            description: data.data.meta_desc,
            keywords: data.data.keywords,
          });
        } else {
          throw new Error('API returned an error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching SEO data:', error);
      });
  }, []);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the data based on the search query
    const filteredData = apiData.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredApiData(filteredData);
  };

  return (
    <div className="main_wrap">
      <Helmet>
        <title>{seoDetails.title} - HRD Group Srl S.</title>
        <meta name="description" content={seoDetails.description} />
        <meta name="keywords" content={seoDetails.keywords} />
      </Helmet>

      <Header />
      <div className="content-wrapper">
        
        <div className="breadcrumb-wrap bg-f br-2">
          <div className="container">
            <div className="breadcrumb-title text-center">
              <h2>{headingData.heading}</h2>
              <p>{headingData.sub_heading}</p>
              <form action="#" className="search-form help_center_form">
                <input
                  type="text"
                  placeholder="Ricerca..."
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <i className="flaticon-search"></i>
                {/*<button type="submit">Ricerca</button>*/}
              </form>
            </div>
          </div>
        </div>

        <section className="faq-wrap style4 ptb-100 support_data"> 
          <div className="container">
            <div className="row justify-content-center">
              {filteredApiData.length > 0
                ? filteredApiData.map((item) => (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={item.id}>
                      <div className="adv-card">
                        <div className="adv-img">
                          <img src={item.photo} alt="Image" />
                        </div>
                        <div className="adv-info">
                          <Link to={`/HelpCenterView/${item._id}`}>
                            <h3>{item.title}</h3>
                          </Link>
                          <p>{item.short_desc}</p>
                        </div>
                      </div>
                    </div>
                  ))
                : apiData.map((item) => (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={item.id}>
                      <div className="adv-card">
                        <div className="adv-img">
                          <img src={item.photo} alt="Image" />
                        </div>
                        <div className="adv-info">
                          <Link to={`/HelpCenterView/${item._id}`}>
                            <h3>{item.title}</h3>
                          </Link>
                          <p>{item.short_desc}</p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </section>

        <CallAction />
      </div>

      <Footer />
    </div>
  );
};

export default HelpCenter;