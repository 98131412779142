import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TestimonialsCarouselProperty from '../components/TestimonialsCarouselProperty';
import CallAction from '../components/CallAction';
import { Helmet } from 'react-helmet';

const PropertyView = () => {
  const { id } = useParams();
  const [propertyData, setPropertyData] = useState(null);
  const navigate = useNavigate();
  const [availabilityData, setAvailabilityData] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const [numGuests, setNumGuests] = useState(0);
  const [bookingMessage, setBookingMessage] = useState('');


const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

  const getUserToken = () => localStorage.getItem('token');

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleWriteReviewClick = () => {
    if (isLoggedIn) {
      navigate(`/AddPropertyTestimonials/${id}`);
    } else {
      navigate('/Login');
    }
  };

const handleCheckAvailability = (e) => {
  e.preventDefault();

  // Check if the user is logged in
  if (!isLoggedIn) {
    // Redirect to the login page with a return URL parameter
    navigate(`/Login?returnUrl=/PropertyView/${id}`);
    return;
  }
  
  const formData = new FormData(e.target);
  const availabilityParams = {
    property_id: propertyData._id,
    start_date: formData.get('start_date'),
    end_date: formData.get('end_date'),
    no_of_guest: formData.get('no_of_guest')
  };

  const queryString = new URLSearchParams(availabilityParams).toString();

  fetch(`https://dayoffice24.it/backend/api/check-availability?${queryString}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        setAvailabilityData(data.data);
        setIsAvailable(true);
        setBookingMessage('La proprietà è disponibile per le date selezionate.');
        
        // Get the button element and its text content
        const buttonElement = e.target.querySelector('button');
        const buttonText = buttonElement.textContent;
        
        if (buttonText === 'Prenota ora') {
          // Redirect to Checkout page with necessary query parameters
          navigate(`/Checkout?start_date=${formData.get('start_date')}&end_date=${formData.get('end_date')}&property_id=${propertyData._id}&no_of_guest=${formData.get('no_of_guest')}`);
        }
      } else {
        setIsAvailable(false);
        setBookingMessage('La struttura non è disponibile per le date selezionate.');
      }
    })
    .catch((error) => {
      console.error('Errore durante il recupero dei dati sulla disponibilità:', error);
    });
};

const availableDates = propertyData && propertyData.availableDates;


  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    return `${day}-${month}`;
  };



  useEffect(() => {
    const token = getUserToken();
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    fetch(`https://dayoffice24.it/backend/api/property/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setPropertyData(data.data);
        } else {
          throw new Error('API returned an error:' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching property data:', error);
      });
  }, [id]);

  if (!propertyData) {
    return <p>Loading...</p>;
  }


  return (
    <div className="main_wrap">

      <Helmet>
        <title>{propertyData.page_title} - HRD Group Srl S.</title>
        <meta name="description" content={propertyData.meta_desc} />
        <meta name="keywords" content={propertyData.keywords} />
      </Helmet>

      <Header />

      <div className="content-wrapper">
        <section className="property-details-wrap pt-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-8">
                <div className="property-details">
                  <div className="row align-items-start">
                    <div className="col-md-8">
                      <div className="property-title">
                        <h2>{propertyData.property_name}</h2>
                        <ul className="property-metainfo list-style">
                          <li>
                            <i className="flaticon-location"></i>
                            {propertyData.address}
                          </li>
                          <li>
                            <i className="flaticon-square"></i>
                            {propertyData.property_size} Mq
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <p className="property-price">
                        €{propertyData.price} <span>/ Giorno</span>
                      </p>
                    </div>
                  </div>
                  <div className="property-img">
                    <div className="gallery">
                      {propertyData.property_image.map((image) => (
                        <div key={image._id} className="item">                         
                            <img
                              src={image.property_image}
                              alt="Property Image"
                            />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="property-desc">
                    <h4>Descrizione</h4>
                    <p>{propertyData.desc}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="sidebar">
                    <div className="search_sidebar sidebar-widget">
                    <h4>Verificare la disponibilità</h4> 

                    <p className="head_section"><strong>Date attualmente disponibili</strong></p>
                    {availableDates && (
                      <p className="dates_before">
                        {formatDate(availableDates.start_date)} - {formatDate(availableDates.end_date)}
                      </p>
                    )}

                    <form onSubmit={handleCheckAvailability} className="property-search-form">
                      <div className="form-group">
                        <input
                          type="date"
                          name="start_date"
                          required
                          className="custom-date-input"
                          placeholder="Registrare"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          min={new Date().toISOString().split('T')[0]} // Set the min attribute to today's date
                        />
                      </div>

                      <div className="form-group">
                         <input
                            type="date"
                            name="end_date"
                            required
                            className="custom-date-input"
                            placeholder="Guardare"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            min={new Date().toISOString().split('T')[0]} // Set the min attribute to today's date
                          />
                      </div>
                      {/* Inside your component */}
                      <div className="form-group">
                        <select name="no_of_guest" id="price-range" onChange={(e) => setNumGuests(e.target.value)}>
                          <option value="0" data-display="Price Range">Numero Ospiti</option>
                          {/* Use Array.from to create an array of numbers from 1 to no_of_guest */}
                          {propertyData && propertyData.no_of_guest &&
                            Array.from({ length: parseInt(propertyData.no_of_guest) }, (_, index) => (
                              <option key={index + 1} value={index + 1}>
                                {index + 1} Guest
                              </option>
                            ))
                          }
                        </select>
                      </div>

                       <p className="heightlihg_text">{bookingMessage}</p>
                       <div className="form-group">
                        {isAvailable ? (
                          <button type="submit" className="btn style2">
                            Prenota ora
                          </button>
                        ) : (
                          <button type="submit" className="btn style2">
                            Verificare la disponibilità
                          </button>
                        )}
                      </div>
                      <input type="hidden" name="property_id" value={propertyData._id} />

                    </form> 

                  </div>

                    <div className="search_sidebar sidebar-widget">
                      <h4>Dettaglio Host</h4>
                      {isLoggedIn ? (
                        <div className="agent-info-box">
                          <div className="agent-name-wrap">
                            <div className="agent-img">
                              <img src={propertyData.user.avatar} alt="Agent Image" />
                            </div>
                            <div className="agent-name"> 
                              <h6>{propertyData.user.name}</h6>
                            </div>
                          </div>

                          <ul className="agent-address list-style"> 
                            <li><i className="ri-map-pin-line"></i>{propertyData.user.address}</li>
                            <li><i className="ri-phone-line"></i>{propertyData.user.mobile_no}</li>
                            <li><i className="ri-mail-send-line"></i><a href={`mailto:${propertyData.user.email}`}>{propertyData.user.email}</a></li>
                          </ul>

                        </div>
                      ) : (
                        <div>
                          <p>Accedi per vedere i dettagli degli Host</p>
                          <Link to="/Login" className="btn style1">
                            Contatta Host
                          </Link>
                        </div>
                      )}
                    </div>


                  <div className="property-desc property_heightligh_box">
                    <h4>Servizi a pagamento disponibili</h4>
                    <ul className="property-annuties list-style">
                      {propertyData.property_service.map((service) => (
                        <li key={service._id}>
                          <i className="ri-checkbox-multiple-line"></i>
                          {service.service.service_name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="single_property_reviews ptb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4>Recensioni</h4>
                <div className="testimonial-slider-two owl-carousel">
                  <TestimonialsCarouselProperty propertyId={id} /> 
                </div>

                <div className="center_btn">
                  <button className="btn style1" onClick={handleWriteReviewClick}>
                    Scrivere una recensione
                  </button>
                </div>

              </div>


              <div className="map_area_bottom">
                <h4>Posizione</h4>
                <div className="map_area" dangerouslySetInnerHTML={{ __html: propertyData.map_link }}></div>
              </div>


            </div>
          </div>
        </section>

      </div>

      <CallAction />

      <Footer />
    </div>
  );
};

export default PropertyView;
