import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const City = () => {
  const { cityId } = useParams();
  const [cityData, setCityData] = useState([]);
  const [cityName, setCityName] = useState('Loading...'); // Set initial value to loading
  const [seoData, setSeoData] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Add a loading indicator state

  useEffect(() => {
    fetchCityData(cityId); // Fetch city data
    fetchCityName(cityId); // Fetch city name
  }, [cityId]);

  const fetchCityData = async (cityId) => {
    try {
      const response = await fetch(`https://dayoffice24.it/backend/api/property/city-id/${cityId}`);
      const data = await response.json();
      console.log('API Response:', data);
      if (data.success && data.data && data.data.length > 0) {
        setCityData(data.data);
      } else {
        setCityData([]); // Set an empty array when no data is found
      }
    } catch (error) {
      console.error('Error fetching city data:', error);
      setCityData([]); // Set an empty array when there's an error
    }
  };

  const fetchCityName = async (cityId) => {
    try {
      const response = await fetch(`https://dayoffice24.it/backend/api/city-name/${cityId}`);
      const data = await response.json();
      console.log('Fetched City Name Data:', data);
      if (data.success && data.city_title) {
        setCityName(data.city_title);

        // Set Helmet SEO data based on API response
        setSeoData({
          page_title: data.seo_data.page_title,
          meta_desc: data.seo_data.meta_desc,
          keywords: data.seo_data.keywords,
        });

        setIsLoading(false); // Set isLoading to false when data is available
      } else {
        console.error('Error fetching city name:', data);
      }
    } catch (error) {
      console.error('Error fetching city name:', error);
    }
  };

  return (
    <div className="main_wrap">
      <Helmet>
        {/* Set Helmet meta tags based on API response */}
        <title>{`${seoData.page_title} - HRD Group Srl S.`}</title>
        <meta name="description" content={seoData.meta_desc} />
        <meta name="keywords" content={seoData.keywords} />
      </Helmet>

      <Header />

      <div className="content-wrapper">
        <div className="breadcrumb-wrap bg-f br-2">
          <div className="container">
            <div className="breadcrumb-title text-center">
              <h2>{cityName}</h2>
              <ul className="breadcrumb-menu list-style">
                <li><a href="/">Home</a></li>
                <li>{cityName}</li>
              </ul>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="loading-indicator">Loading...</div>
        ) : (
          <section className="listing-wrap ptb-100">
            <div className="container">
              <div className="row">
                {cityData.length === 0 ? (
                  <div className="col-12 text-center">
                    <p className="no_property_name">No properties found for this city.</p>
                  </div>
                ) : (
                  cityData.map((property) => (
                    <div key={property._id} className="col-xl-4 col-lg-6 col-md-6">
                      <div className="property-card style4">
                        <div className="property-img">
                          <Link to={`/PropertyView/${property._id}`}>
                            <img src={property.thumb_image} alt="Image" />
                          </Link>
                          <span className="property-status">{property.property_type}</span>
                        </div>
                        <div className="property-info">
                          <h3>
                            <Link to={`/PropertyView/${property._id}`}>{property.property_name}</Link>
                          </h3>
                          <p>{property.availableDates.view_date}</p>
                          <p>
                            <i className="flaticon-location"></i>
                            {property.address}
                          </p>
                          <ul className="property-metainfo list-style">
                            <li>
                              <i className="flaticon-user-avatar"></i>
                              {property.no_of_guest}
                            </li>
                            <li>
                              <i className="flaticon-square"></i>
                              {property.property_size} sqft
                            </li>
                          </ul>
                          <p className="property-price">
                            €{property.price}/<span>{property.price_charge_type}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default City;
