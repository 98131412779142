import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios'; // Import axios

const AddTestimonials = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    short_desc: '',
    name: '',
    position: '',
    star_rating: '',
    email: '',
    user_img: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({ ...prevState, user_img: e.target.files[0] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create form data for sending multipart/form-data
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    axios
    .post('https://dayoffice24.it/backend/api/testimonial/store', formDataToSend)
    .then((response) => {
      if (response.data.success) {
        console.log('Testimonial submitted successfully!');
        setSuccessMessage('Testimonial submitted successfully!');
        // Redirect to a success page or navigate to another route if needed
        navigate('/'); // Replace '/success' with the desired success route
      } else {
        console.error('Failed to submit testimonial:', response.data.message);
        setErrorMessage(response.data.message); // Use the error message from the API response
        setSuccessMessage(''); // Clear the success message
      }
    })
    .catch((error) => {
      console.error('Error submitting testimonial:', error);
      setErrorMessage('Errore durante linvio della testimonianza. Per favore riprova.');
      setSuccessMessage(''); // Clear the success message
    });
};

  return (
    <div className="main_wrap">
      <Header />
      <div className="content-wrapper">
        <section className="section_padding pb-75 pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="tou_booking_form_Wrapper">
                  <div className="booking_tour_form">
                    <h3 className="heading_themed text-center mb-30">
                     Aggiungi le tue testimonianze
                    </h3> 
                    <div className="tour_booking_form_box property-desc">
                      <form className="review-form" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group">
                              <p>Your ratings for this property</p>
                              <div className="star-rating">
                                <input
                                  id="star-5"
                                  type="radio"
                                  name="star_rating"
                                  value="5"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-5" title="5 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 5 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-4"
                                  type="radio"
                                  name="star_rating"
                                  value="4"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-4" title="4 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 4 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-3"
                                  type="radio"
                                  name="star_rating"
                                  value="3"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-3" title="3 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 3? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-2"
                                  type="radio"
                                  name="star_rating"
                                  value="2"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-2" title="2 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 2 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>
                                <input
                                  id="star-1"
                                  type="radio"
                                  name="star_rating"
                                  value="1"
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="star-1" title="1 stars">
                                  <i
                                    className={`active ri-star-fill ${
                                      formData.star_rating >= 1 ? 'checked' : ''
                                    }`}
                                    aria-hidden="true"
                                  ></i>
                                </label>


                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 text-md-end">
                            <div className="file form-group">
                              <label htmlFor="input-file">Upload Image</label>
                              <input
                                id="input-file"
                                type="file"
                                name="user_img"
                                onChange={handleFileChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Titolo"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Nome"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Posizione"
                                name="position"
                                value={formData.position}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="email"
                                placeholder="E-mail"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>


                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                id="msg"
                                cols="30"
                                rows="6"
                                placeholder="Scrivere una recensione"
                                name="short_desc"
                                value={formData.short_desc}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button type="submit" className="btn style2">
                             invia recensione
                            </button>
                          </div>

                          {errorMessage && (
                            <div className="col-lg-12 mt-3">
                              <div className="alert alert-danger" role="alert">
                                {errorMessage}
                              </div>
                            </div>
                          )}
                          {successMessage && (
                            <div className="col-lg-12 mt-3">
                              <div className="alert alert-success" role="alert">
                                {successMessage}
                              </div>
                            </div>
                          )}

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AddTestimonials;
