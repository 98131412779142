import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Axios from 'axios';
import { Helmet } from 'react-helmet';

const BecomeHost = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });

  useEffect(() => {
    fetchSeoDetails();
  }, []);

  const fetchSeoDetails = async () => {
    try {
      const response = await fetch('https://dayoffice24.it/backend/api/seo-details/host');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (!username || !name || !email || !password) {
      setError('Please fill all the required fields.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    const termsCheckbox = document.getElementById('terms');
    if (!termsCheckbox.checked) {
      setError('Please accept the terms and conditions.');
      return;
    }

    try {
      const response = await Axios.post('https://dayoffice24.it/backend/api/register', {
        name: name,
        email: email,
        password: password,
        assign_role: 'host',
        username: username,
      });

      if (response.data.success) {
        setError('');
        setSuccessMessage('Registration completed successfully. You can now log in with your credentials.');
        // Clear form fields after successful registration
        setUsername('');
        setFullName('');
        setEmail('');
        setPassword('');

        // Redirect to the Login page after a short delay
        setTimeout(() => {
          navigate('/Login');
        }, 500);
      } else {
        setError(response.data.message);
        setSuccessMessage('');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="main_wrap">
      <Helmet>
        <title>{seoDetails.title} - HRD Group Srl S.</title>
        <meta name="description" content={seoDetails.description} />
        <meta name="keywords" content={seoDetails.keywords} />
      </Helmet>
      <Header />
      <div className="content-wrapper">
        <section className="property-details-wrap pt-75 pb-75">
          <div className="container">
            <div className="row align-items-center login_bg gx-5">
              <div className="col-md-6">
                <div className="login-bg bg-f"></div>
              </div>
              <div className="col-md-6">
                <div className="login-content">
                  <h2>Become a host</h2>
                  <p>Fill out the form to create an account</p>
                  <form className="user-form" onSubmit={handleRegister}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="username"
                            name="username"
                            type="text"
                            placeholder="Username"
                            required=""
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="fname"
                            name="name"
                            type="text"
                            placeholder="Full Name"
                            required=""
                            value={name}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            required=""
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="pwd_2"
                            name="pwd_2"
                            type="password"
                            placeholder="Password"
                            required=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-12">
                        <div className="checkbox style3">
                          <input type="checkbox" id="terms" />
                          <label htmlFor="terms">
                            I agree to the{' '}
                            <a target="_blank" className="link style1" href="/Terms">
                              Terms and Conditions
                            </a>{' '}
                            and{' '}
                            <a target="_blank" className="link style1" href="/Privacy">
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button className="btn style1">Sign Up Now</button>
                        </div>
                      </div>
                      {error && (
                        <div className="col-lg-12">
                          <p className="text-danger">{error}</p>
                        </div>
                      )}
                      {successMessage && (
                        <div className="col-lg-12">
                          <p className="text-success">{successMessage}</p>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default BecomeHost;
