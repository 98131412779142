import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";

const Chatboat = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const usertoken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [messageText, setMessageText] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    if (!usertoken) {
      navigate("/login");
      return;
    }

    const headers = {
      Authorization: `Bearer ${usertoken}`,
      "Content-Type": "application/json",
    };

    axios
      .get("https://dayoffice24.it/backend/api/chat/user-list", { headers })
      .then((response) => {
        if (response.data.success) {
          setUsers(response.data.data.user_list);
          setFilteredUsers(response.data.data.user_list);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

    // Fetch the current user's profile
    axios
      .get("https://dayoffice24.it/backend/api/profile", { headers })
      .then((response) => {
        if (response.data.success) {
          setCurrentUser(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching current user profile:", error);
      });
  }, [usertoken, navigate]);

  useEffect(() => {
    // Your existing code for fetching messages based on selectedUser
  }, [selectedUser, usertoken]);

  useEffect(() => {
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [users, searchQuery]);

  useEffect(() => {
    if (selectedUser && currentUser) {
      console.log("Fetching messages for selectedUser:", selectedUser);
      console.log("Current user:", currentUser);

      const headers = {
        Authorization: `Bearer ${usertoken}`,
        "Content-Type": "application/json",
      };

      // Include sender_id in the request payload
      const requestData = {
        sender_id: currentUser._id,
      };

      axios
        .post(
          `https://dayoffice24.it/backend/api/chat/get-message/${selectedUser}`,
          requestData,
          { headers }
        )
        .then((response) => {
          console.log("API response:", response.data);
          if (response.data.success) {
            setMessages(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
        });
    }
  }, [selectedUser, usertoken, currentUser]);

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (!selectedUser || !currentUser || !messageText) {
      // Ensure that all necessary data is available
      return;
    }

    const headers = {
      Authorization: `Bearer ${usertoken}`,
      "Content-Type": "application/json",
    };

    const requestData = {
      user_id: selectedUser,
      sender_id: currentUser._id,
      body: messageText,
    };

    axios
      .post(
        "https://dayoffice24.it/backend/api/chat/send-message",
        requestData,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          // Update messages state with the new message
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              // _id: response.data.data._id,
              sender_id: currentUser._id,
              body: messageText,
              created_at: new Date().toISOString(),
            },
          ]);

          setMessageText(""); // Clear the message text input
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  return (
    <div className="main_wrap">
      <Header />
      <div className="content-wrapper">
        <section className="middle_blocks grey_bg_wrapper pb-75 pt-75">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="container_chat_boat clearfix">
                  <div className="people-list" id="people-list">
                    <div className="search">
                      <input
                        type="text"
                        placeholder="Ricerca"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <i className="fa fa-search"></i>
                    </div>
                    <div className="user_list">
                      <ul className="list">
                        {filteredUsers.map((user) => (
                          <li
                            className={`clearfix ${
                              user._id === selectedUserId ? "selected" : ""
                            }`} // Apply "selected" class if user._id matches selectedUserId
                            key={user._id}
                          >
                          <img src={user.avatar} alt="avatar" />
                            <div className="about">
                              <div className="name">
                                <a
                                  href={`?user_id=${user._id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedUserId(user._id); // Update selectedUserId
                                    setSelectedUser(user._id); // Update selectedUser
                                  }}
                                >
                                  {user.name}
                                  &nbsp;
                                  {user.total_msg_count > 0 ? (
                                    <span
                                      className="badge rounded-pill badge-notification bg-danger"
                                      title="chat"
                                    >
                                      {user.total_msg_count}
                                    </span>
                                  ) : null}
                                </a>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="chat">
                    <div className="chat-header clearfix">
                      <div className="chat-about">
                        <div className="chat-with">
                          <i class="ri-message-2-fill"></i> Cronologia chat
                        </div>
                      </div>
                    </div>
                    {selectedUser ? (
                      <div className="chat-history">
                        <ul>
                          {messages.map((message) => (
                            <li
                              key={message._id}
                              className={
                                message.sender_id === selectedUser
                                  ? "clearfix other-messages"
                                  : "clearfix our-messages"
                              }
                            >
                              <div
                                className={`message-data ${
                                  message.sender_id === selectedUser
                                    ? "other-messages"
                                    : "our-messages"
                                }`}
                              >
                                <span className="message-data-name">
                                  {message.sender && message.sender.name}
                                </span>
                                <span className="message-data-time">
                                  {new Date(
                                    message.created_at
                                  ).toLocaleString()}
                                </span>
                              </div>
                              <div
                                className={`message ${
                                  message.sender_id === selectedUser
                                    ? "other-messages"
                                    : "our-messages"
                                }`}
                              >
                                {message.body}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="no-user-selected">
                        Seleziona un utente per iniziare a chattare.
                      </div>
                    )}
                    {selectedUser && (
                      <div className="chat-message clearfix">
                        <input
                          type="hidden"
                          id="sender_id"
                          name="sender_id"
                          value={currentUser ? currentUser._id : ""}
                        />
                        <form onSubmit={handleSendMessage}>
                          <textarea
                            name="message-to-send"
                            required
                            id="message-to-send"
                            placeholder="Scrivi il tuo messaggio"
                            rows="3"
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                          ></textarea>
                          <i className="fa fa-file-o"></i> &nbsp;&nbsp;&nbsp;
                          <i className="fa fa-file-image-o"></i>
                          <button>Inviare</button>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Chatboat;