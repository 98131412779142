import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import '../assets/css/bootstrap.min.css';
import '../assets/css/remixicon.css';
import '../assets/css/flaticon.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';

const Header = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);
  const [user, setUser] = useState(null);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userData, setUserData] = useState(null);

  const toggleMobileMenu = () => {
    setMobileMenuVisible((prevState) => !prevState); 
  };

  useEffect(() => {
    fetch('https://dayoffice24.it/backend/api/menu')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setMenuItems(data.data);
        } else {
          throw new Error('API returned an error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching menu data:', error);
      });

    const usertoken = localStorage.getItem('token');
    if (usertoken) {
      // Fetch the user's profile data using the profile API
      fetch('https://dayoffice24.it/backend/api/profile', {
        redirect: 'manual',
        headers: {
          Authorization: `Bearer ${usertoken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status === 200) {
            // Handle successful response and update the state
            return response.json();
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .then((data) => {
          if (data.success) {
            setUser(true);
            setUserData(data.data); // Set the user's profile data received from the API to the state
          } else {
            throw new Error('API returned an error: ' + data.message);
          }
        })
        .catch((error) => {
          console.error('Error fetching profile data:', error);
          setUser(false);
        });
    } else {
      setUser(false);
    }
  }, []);

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const closeMobileMenu = () => {
    setMobileMenuVisible(false);
  };

  // Logout function to handle the logout API call
  const handleLogout = () => {
    fetch('https://dayoffice24.it/backend/api/logout', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Clear the user data from local storage
          localStorage.removeItem('token'); // This removes the token
          // Manually redirect the user to the homepage
          window.location.href = '/';
        } else {
          console.error('Logout failed:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <header className="header-wrap style1">
      <div className="container">
        <nav className="navbar navbar-expand-md navbar-light">
          <Link className="navbar-brand" to="/"><img className="logo-light" src={logo} alt="logo" /></Link>
          <div className={`navbar-collapse main-menu-wrap ${mobileMenuVisible ? 'open' : ''}`} id="navbarSupportedContent">
            <div className="menu-close d-lg-none">
              <a href="javascript:void(0)" onClick={toggleMobileMenu}> <i className="ri-close-line"></i> </a>
            </div>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              {!user && (
                <li className="nav-item">
                  <Link to="/BecomeHost" className="nav-link">
                    Diventa Host
                  </Link>
                </li>
              )}
              {menuItems.length > 0 ? (
                menuItems.map((menuItem) => (
                  <li key={menuItem._id} className="nav-item">
                    <Link to={`/category/${menuItem._id}`} className="nav-link">
                      {menuItem.category_name}
                    </Link>
                  </li>
                ))
              ) : (
                <li className="nav-item">
                  <span className="nav-link">Loading...</span>
                </li>
              )}
            </ul>
            <div className="others-options">
              {userData ? (
                <div className="block_after_login">
                  <div className="dropdown" onClick={toggleDropdown}>
                    <div className="profile_name">
                      {userData && (
                        <div className="profile_name">
                          <img src={userData.avatar} alt="Profile" />
                          {userData.name} <i className="ri-arrow-drop-down-line"></i>
                        </div>
                      )}
                    </div>
                    <div className={`dropdown-content ${showDropdown ? 'show' : ''}`}>
                      <Link className="dropdown-item" to="/Profile">Profilo</Link>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to="/EditProfile">Modifica Profilo</Link>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to="/ChangePassword">Cambiare la password</Link>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to="/OrderHistory">Cronologia ordini</Link>
                      <div className="dropdown-divider"></div>
                      <button onClick={handleLogout} className="dropdown-item">Disconnettersi</button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="header-btn">
                  <Link className="btn style3" to="/Login">Accedi</Link>
                  <a className="btn style1" href="https://dayoffice24.it/backend/login" target="_blank" rel="noopener noreferrer">Accesso Host</a>
                </div>
              )}
            </div>
          </div>
        </nav>
        <div className="mobile-bar-wrap">
          <div className="mobile-menu d-lg-none">
            <a onClick={toggleMobileMenu}>
              <i className="ri-menu-line"></i>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
