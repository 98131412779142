import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import {
  Elements,
  ElementsConsumer,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51Nu8czI5jJlpentRUnu7ELYMutrbQhIo7DUqh7YR9Wft6Sk21LRKXrQZLRmSRAHSC1Adkp7GEkw89SFVfz38WW7B00TsyqbBpx"
);

const Payment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get("bookingId"); // Assuming 'bookingId' is the parameter name
  const [bookingDetails, setBookingDetails] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("paypal");
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentError, setPaymentError] = useState(null);

  useEffect(() => {
    const usertoken = localStorage.getItem("token");
    if (!usertoken) {
      // Redirect to the login page or handle authentication as needed
      return;
    }

    const headers = {
      Authorization: `Bearer ${usertoken}`,
      "Content-Type": "application/json",
    };

    // Fetch booking details
    fetch(`https://dayoffice24.it/backend/api/booking/details/${bookingId}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("API request failed with status: " + response.status);
        }
      })
      .then((data) => {
        if (data && data.success) {
          setBookingDetails(data.data);
          // Fetch PaymentIntent client secret from your server
          fetch("https://dayoffice24.it/backend/api/payment/stripe", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${usertoken}`,
            },
            body: JSON.stringify({
              amount: data.data.payable_amount,
              currency: "EUR",
            }),
          })
            .then((response) => response.json())
            .then((intentData) => {
              setClientSecret(intentData.clientSecret); // Set the client secret here
            })
            .catch((error) => {
              console.error(
                "Error fetching PaymentIntent client secret:",
                error
              );
            });
        } else {
          console.error("Booking details not found.");
          // Handle any other error conditions here
        }
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      });
  }, [bookingId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  const handlePaymentSubmit = async (e, elements, stripe) => {
    e.preventDefault();

    const usertoken = localStorage.getItem("token");
    if (!usertoken) {
      // Redirect to the login page or handle authentication as needed
      return;
    }

    const headers = {
      Authorization: `Bearer ${usertoken}`,
      "Content-Type": "application/json",
    };

    if (selectedPaymentMethod === "paypal") {
      const paymentData = {
        booking_no: bookingDetails.booking_no,
        final_total: bookingDetails.payable_amount,
      };

      // Make a request to your server to initiate PayPal payment
      fetch("https://dayoffice24.it/backend/api/payment/paypal", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(paymentData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.success) {
            window.location.href = data.redirect_url;
          } else {
            console.error("Payment request failed.");
          }
        })
        .catch((error) => {
          console.error("Error sending payment request:", error);
        });
    } else if (selectedPaymentMethod === "stripe") {
      try {
        if (!stripe || !elements) {
          throw new Error("Stripe.js has not loaded yet.");
        }

        const cardElement = elements.getElement(CardElement);

        // Create a token using the CardElement
        const { token, error } = await stripe.createToken(cardElement);

        if (error) {
          console.error("Error creating token:", error);
          // Handle token creation error
          setPaymentError(error.message);
        } else {
          // Send the token to your server for payment processing
          const response = await fetch(
            "https://dayoffice24.it/backend/api/payment/stripe",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${usertoken}`,
              },
              body: JSON.stringify({
                token: token.id,
                amount: bookingDetails.payable_amount,
                booking_no: bookingDetails.booking_no,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          if (data.client_secret) {
            // Confirm the PaymentIntent with the client secret
            const result = await stripe.confirmCardPayment(data.client_secret, {
              payment_method: {
                card: cardElement,
                // Add any other payment method details if needed
              },
            });

            if (result.error) {
              console.error("Payment confirmation failed:", result.error);
              // Handle payment confirmation error
              setPaymentError(result.error.message);
            } else 

                if (result.paymentIntent.status === "succeeded") {
                  console.log("Payment succeeded:", result.paymentIntent);
                    const transactionId = result.paymentIntent.id;
                    const bookingNo = bookingDetails.booking_no;
                    const apiUrl = `https://dayoffice24.it/backend/api/payment/stripe/return/${bookingNo}/${transactionId}`;
                    fetch(apiUrl, {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${usertoken}`,
                      },
                    })
                      .then((response) => response.json())
                      .then((apiResponse) => {
                         if (apiResponse.success === true) {
                          // Redirect to the PaymentSuccess URL
                          window.location.href =
                            "https://dayoffice24.it/PaymentSuccess";
                        } else {
                          // Handle the case where success is not true, you can show an error message
                          window.location.href =
                            "https://dayoffice24.it/PaymentFailure";
                        }
                      })
                      .catch((apiError) => {
                        console.error("API Error:", apiError);
                      });
                  }
            } else {
              console.error(
                "Error fetching PaymentIntent client secret:",
                response.statusText
              );
            }
          }
        } catch (error) {
          console.error("Error processing Stripe payment:", error);
          setPaymentError("Failed to process payment. Please try again.");
        }
      }
    };

  return (
    <div className="main_wrap">
      <Header />
      <div className="content-wrapper">
        <div className="breadcrumb-wrap bg-f br-2">
          <div className="container">
            <div className="breadcrumb-title text-center">
              <h2>Dettagli della prenotazione</h2>
              <ul className="breadcrumb-menu list-style">
                <li>
                  <a href="/">Casa</a>
                </li>
                <li>Dettagli della prenotazione</li>
              </ul>
            </div>
          </div>
        </div>

        <section
          id="tour_booking_submission"
          className="section_padding pb-75 pt-100"
        >
          <div className="container">
            <div className="row justify-content-center payment_form">
              <div className="col-xs-12 col-md-6">
                <div className="panel panel-default">
                  <div className="panel-body payment_details tour_details_right_boxed">
                    {bookingDetails ? (
                      <>
                        <p>
                          Numero di prenotazione:{" "}
                          <span>{bookingDetails.booking_no}</span>
                        </p>
                        <p>
                          Nome della proprietà:{" "}
                          <span>{bookingDetails.property.property_name}</span>
                        </p>
                        <p>
                          Servizi immobiliari:{" "}
                          <span>
                            {" "}
                            {bookingDetails.service_booking
                              .map((service) => service.service_name)
                              .join(", ")}
                          </span>
                        </p>
                        <p>
                          Prenotazione da:{" "}
                          <span> {formatDate(bookingDetails.start_date)}</span>
                          <br />
                          Prenotazione a:{" "}
                          <span>{formatDate(bookingDetails.end_date)}</span>
                        </p>
                        <p>
                          Importo pagabile:{" "}
                          <span>€{bookingDetails.payable_amount}</span>
                        </p>
                      </>
                    ) : (
                      <p>Caricamento dei dettagli della prenotazione...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center payment_form_options">
              <div className="col-xs-12 col-md-6">
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ elements, stripe }) => (
                      <form
                        onSubmit={(e) =>
                          handlePaymentSubmit(e, elements, stripe)
                        }
                        id="payment_checked"
                      >
                        <div className="booking_tour_form">
                          <h3 className="heading_theme">Metodo di pagamento</h3>
                          <div className="tour_booking_form_box">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paymentMethod"
                                id="paypalRadio"
                                value="paypal"
                                checked={selectedPaymentMethod === "paypal"}
                                onChange={() =>
                                  setSelectedPaymentMethod("paypal")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="paypalRadio"
                              >
                                PayPal
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paymentMethod"
                                id="stripeRadio"
                                value="stripe"
                                checked={selectedPaymentMethod === "stripe"}
                                onChange={() =>
                                  setSelectedPaymentMethod("stripe")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="stripeRadio"
                              >
                                Stripe
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* Stripe Card Element */}
                        {selectedPaymentMethod === "stripe" && (
                          <div className="stripe-card-element">
                            <CardElement />
                          </div>
                        )}

                        {/* Payment Button */}
                        <div className="book_now_btn pay_now_full_width text-center">
                          <button className="btn style1" type="submit">
                            Paga ora
                          </button>
                        </div>
                      </form>
                    )}
                  </ElementsConsumer>
                </Elements>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Payment;
