import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Axios from 'axios';
import { Helmet } from 'react-helmet';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 
  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });


  useEffect(() => {
    fetchSeoDetails();
  }, []);

  const fetchSeoDetails = async () => {
    try {
      const response = await fetch('https://dayoffice24.it/backend/api/seo-details/forgetPassword');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  };

  
  const handleResetPassword = async (event) => {
    event.preventDefault();

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Si prega di inserire un indirizzo email valido.'); 
      setSuccessMessage('');
      return;
    }
    try {
      const response = await Axios.post('https://dayoffice24.it/backend/api/forget-password', {
        email: email,
      });

      if (response.data.success) {
        setError('');
        setSuccessMessage('Controlla la tua email per ulteriori istruzioni.');
        setEmail('');
       
      } else {
        setError(response.data.message);
        setSuccessMessage('');
      }
    } catch (error) {
      setError('Si è verificato un errore. Per favore riprova più tardi.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="main_wrap">

     <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
     </Helmet>

      <Header />
      <div className="content-wrapper">
        <section className="property-details-wrap pt-75 pb-75">
          <div className="container">
            <div className="row align-items-center justify-content-center gx-5">
              <div className="col-md-6">
                <div className="login-content">
                  <h2>Ha dimenticato la password?</h2>
                  <form className="user-form" onSubmit={handleResetPassword} action="#">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Indirizzo e-mail"
                            required=""
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button className="btn style1">Inviare</button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {error && <p className="text-danger">{error}</p>}
                  {successMessage && <p className="text-success">{successMessage}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;

