import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CallAction from '../components/CallAction';

const PropertySearch = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [propertyData, setPropertyData] = useState([]);

    const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

  // Use useLocation to extract the query parameters from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const search_input = searchParams.get('search_input');
  const start_date = searchParams.get('start_date');
  const end_date = searchParams.get('end_date');
  const no_of_guest = searchParams.get('no_of_guest');

  useEffect(() => {
    fetch('https://dayoffice24.it/backend/api/menu')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.success) {
          setMenuItems(data.data);
        } else {
          throw new Error('API returned an error:' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching menu data:', error);
      });
  }, []);

  const handleSearch = (searchParams) => {
    const queryString = new URLSearchParams(searchParams).toString();

    fetch(`https://dayoffice24.it/backend/api/property/search-from-home?${queryString}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.success) {
          setPropertyData(data.data.propertyByCityId);
        } else {
          throw new Error('API returned an error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching property data:', error);
      });
  };

  useEffect(() => {
    if (search_input && start_date && end_date && no_of_guest) {
      const initialSearchParams = {
        search_input,
        start_date,
        end_date,
        no_of_guest,
      };
      handleSearch(initialSearchParams);
    }
  }, [search_input, start_date, end_date, no_of_guest]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const searchParams = Object.fromEntries(formData.entries());
    handleSearch(searchParams);
  };

  return (
    <div className="main_wrap">
      <Header />

      <div className="content-wrapper">
        <section className="property-details-wrap pt-100 pb-75">
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="sidebar">
                  <div className="search_sidebar sidebar-widget">
                    <h4>Ricerca</h4>
                    <form onSubmit={handleSubmit} className="property-search-form">
                      <div className="form-group">
                        <input type="text" name="search_input" placeholder="Name" required />
                      </div>
                      <div className="form-group">
                        <input
                          type="date"
                          name="start_date"
                          className="custom-date-input"
                          placeholder="Registrare"
                          value={startDate}
                            onChange={(e) => setStartDate(e.target.value)} min={new Date().toISOString().split('T')[0]} 
                        />
                      </div>
                      <div className="form-group">
                        <input
                        type="date"
                        name="end_date"
                        className="custom-date-input"
                        placeholder="Guardare"
                         value={endDate}
                            onChange={(e) => setEndDate(e.target.value)} min={new Date().toISOString().split('T')[0]}   
                      />
                      </div>
                      <div className="form-group">
                        <select name="no_of_guest" id="price-range">
                          <option value="0" data-display="Price Range">Numero Ospiti</option>
                          <option value="1">1 Ospite</option>
                          <option value="2">2 Ospite</option>
                          <option value="3">3 Ospite</option>
                          <option value="4">4 Ospite</option>
                          <option value="5">5 Ospite</option>
                          <option value="6">6 Ospite</option>
                          <option value="7">7+ Ospite</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn style2">Ricerca</button>
                      </div>
                    </form>
                  </div>

                  <div className="category_sidebar sidebar-widget"> 
                    <h4>Categorie</h4>
                    <ul className="categories_list">
                      {menuItems.length > 0 ? (
                        menuItems.map((menuItem) => (
                          <li key={menuItem._id} className="nav-item2">
                            <Link to={`/category/${menuItem._id}`} className="nav-link2">
                              {menuItem.category_name}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li className="nav-item"> 
                          <span className="nav-link">Loading...</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-8"> 
                <h2 className="mb-20">risultati di ricerca</h2> 
                <div className="row">
                  {Array.isArray(propertyData) && propertyData.length > 0 ? (
                    propertyData.map((property) => (
                      <div key={property._id} className="col-xl-6 col-lg-6 col-md-6">
                        <div className="property-card style4">
                          <div className="property-img">
                            <Link to={`/PropertyView/${property._id}`}><img src={property.thumb_image}  alt="Image" /></Link>
                             <span className="property-status">{property.property_type}</span>
                          </div>
                          <div className="property-info">
                            <h3><Link to={`/PropertyView/${property._id}`}>{property.property_name}</Link></h3>
                             <p>{property.availableDates.view_date}</p>
                            <p><i className="flaticon-location"></i>{property.address}</p>
                            <ul className="property-metainfo list-style">
                              <li><i className="flaticon-double-bed"></i>{property.no_of_guest}</li>
                              <li><i className="flaticon-square"></i>{property.property_size} sqft</li>
                            </ul>
                            <p className="property-price">€{property.price}/<span>mese</span></p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-xl-12">
                      <p>Non sono disponibili strutture per le date inserite.</p>
                      <a class="btn style1 mb_15_btn" href="/">Prova una nuova ricerca</a>
                      <p>
                      Leggi le FAQ nell <a href="/HelpCenter">  Centro assistenza </a>  o scrivici a info@dayoffice24.it</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <CallAction />
      <Footer />
    </div>
  );
};

export default PropertySearch;
