import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const HelpCenterView = () => {
  const { id } = useParams();
  const [helpCenterData, setHelpCenterData] = useState(null);
  const [seoData, setSeoData] = useState({
    page_title: 'Default Title',
    keywords: 'Default Keywords',
    meta_desc: 'Default Description'
  });

  useEffect(() => {
    // Fetch help center data
    fetch(`https://dayoffice24.it/backend/api/help-center/detail/${id}`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.data) {
          setHelpCenterData(data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching help center item data:', error);
      });

    // Fetch SEO data
    fetch(`https://dayoffice24.it/backend/api/help-center/detail-api/${id}`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.data) {
          setSeoData({
            page_title: data.data.page_title,
            keywords: data.data.keywords,
            meta_desc: data.data.meta_desc
          });
        }
      })
      .catch(error => {
        console.error('Error fetching SEO data:', error);
      });
  }, [id]);

  return (
    <div className="main_wrap">
      <Helmet>
        <title>{seoData.page_title}</title>
        <meta name="description" content={seoData.meta_desc} />
        <meta name="keywords" content={seoData.keywords} />
      </Helmet>

      <Header />
      <div className="content-wrapper">
        <section className="property-details-wrap pt-100 pb-75">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                {helpCenterData ? (
                  <div className="property-details text-center">
                    <div className="row align-items-start">
                      <div className="col-md-12">
                        <div className="property-title">
                          <span className="help_tut">Tutorial di aiuto</span>
                          <h2>{helpCenterData.title}</h2>
                          <ul className="property-metainfo list-style">
                            <li>
                              <i className="flaticon-square"></i>Help Center
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="property-img text-center">
                      <img src={helpCenterData.photo} alt="Image" />
                    </div>
                    <div className="property-desc text-center">
                      <p>{helpCenterData.description}</p>
                    </div>
                  </div>
                ) : (
                  <div>Loading...</div> 
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default HelpCenterView;
