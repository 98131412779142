import React, { useEffect, useState } from 'react';


const OAuthCallback = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate an API call to your Laravel backend to authenticate the user.
    // Replace 'your_backend_url' with the actual URL of your Laravel backend.
    fetch('https://dayoffice24.it/backend/api/auth/google-user/callback')
      .then((response) => response.json())
      .then((data) => {
        const { access_token, user } = data;
        if (access_token) {
          // Store the access_token securely in local storage or a cookie.
          localStorage.setItem('access_token', access_token);
          // You can also store user data if needed.
          localStorage.setItem('user', JSON.stringify(user));
          // Redirect to the profile page or any protected route.
          window.location.href = '/Profile';
        } else {
          // Handle authentication error.
          console.error('Authentication failed');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    // Simulate an API call to your Laravel backend to authenticate the user.
    // Replace 'your_backend_url' with the actual URL of your Laravel backend.
    fetch('https://dayoffice24.it/backend/api/auth/linkedin-user/callback')
      .then((response) => response.json())
      .then((data) => {
        const { access_token, user } = data;
        if (access_token) {
          // Store the access_token securely in local storage or a cookie.
          localStorage.setItem('access_token', access_token);
          // You can also store user data if needed.
          localStorage.setItem('user', JSON.stringify(user));
          // Redirect to the profile page or any protected route.
          window.location.href = '/Profile';
        } else {
          // Handle authentication error.
          console.error('Authentication failed');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);



  useEffect(() => {
    // Simulate an API call to your Laravel backend to authenticate the user.
    // Replace 'your_backend_url' with the actual URL of your Laravel backend.
    fetch('https://dayoffice24.it/backend/auth/twitter-user/callback')
      .then((response) => response.json())
      .then((data) => {
        const { access_token, user } = data;
        if (access_token) {
          // Store the access_token securely in local storage or a cookie.
          localStorage.setItem('access_token', access_token);
          // You can also store user data if needed.
          localStorage.setItem('user', JSON.stringify(user));
          // Redirect to the profile page or any protected route.
          window.location.href = '/Profile';
        } else {
          // Handle authentication error.
          console.error('Authentication failed');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  return (
    <div>
      {loading ? (
        <p>Authenticating...</p>
      ) : (
        <p>Autenticazione riuscita! Reindirizzamento...</p>
      )}
    </div>
  );
};
export default OAuthCallback;
