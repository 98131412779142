import React, { useState, useEffect } from 'react';
import { useNavigate, useParams,Navigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const EditProfile = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { id } = useParams();
  const [userId, setUserId] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    // Initial state for the form fields
    name: '',
    avatar: null,
    email: '',
    mobile_no: '',
    dob: '',
    status: '',
    gender: '',
    address: '',
    desc: '',
    company_name: '',
    company_contact_no: '',
    gst_no: '',
    vat_no: '',
    sdi: '',
    pec: '',
    company_address: '',
    account_holder_name: '',
    bank_name: '',
    bank_account_no: '',
    ifsc_code: '',
    upi_id: '',
    swift_code: '',
    bank_address: '',
    uid: '',
  });

useEffect(() => {
  setUserId(id);
  const usertoken = localStorage.getItem('token');
  fetch('https://dayoffice24.it/backend/api/profile', {
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        // Set the user's existing profile data to the state
        setFormData({
          ...formData,
          name: data.data.name || '', // Use an empty string if name is null
          avatar: null, // Set the avatar separately if it's not included in the API response
          email: data.data.email || '', // Use an empty string if email is null
          mobile_no: data.data.mobile_no || '', // Use an empty string if mobile_no is null
          dob: data.data.dob || '', // Use an empty string if dob is null
          status: data.data.status || '', // Use an empty string if status is null
          gender: data.data.gender || '', // Use an empty string if gender is null
          address: data.data.address || '', // Use an empty string if address is null
          desc: data.data.desc || '', // Use an empty string if desc is null
          company_name: data.data.visitor.company_name || '', // Use an empty string if company_name is null
          company_contact_no: data.data.visitor.company_contact_no || '', // Use an empty string if company_contact_no is null
          gst_no: data.data.visitor.gst_no || '', // Use an empty string if gst_no is null
          vat_no: data.data.visitor.vat_no || '', // Use an empty string if vat_no is null
          sdi: data.data.visitor.sdi || '', // Use an empty string if sdi is null
          pec: data.data.visitor.pec || '', // Use an empty string if pec is null
          company_address: data.data.visitor.company_address || '', // Use an empty string if company_address is null
          account_holder_name: data.data.account_holder_name || '', // Use an empty string if account_holder_name is null
          bank_name: data.data.bank_name || '', // Use an empty string if bank_name is null
          bank_account_no: data.data.bank_account_no || '', // Use an empty string if bank_account_no is null
          ifsc_code: data.data.ifsc_code || '', // Use an empty string if ifsc_code is null
          upi_id: data.data.upi_id || '', // Use an empty string if upi_id is null
          swift_code: data.data.swift_code || '', // Use an empty string if swift_code is null
          bank_address: data.data.bank_address || '', // Use an empty string if bank_address is null
          uid: data.data._id,
        });
      } else {
        console.error('Failed to fetch profile data:', data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching profile data:', error);
    });
}, [id]);


const handleChange = (e) => {
  const { name, value, type, files } = e.target;
  const newValue = type === 'file' ? files[0] : value;

  setFormData((prevData) => ({
    ...prevData,
    [name]: newValue !== undefined ? newValue : '', // Set to empty string if undefined
  }));
};

  const hideSuccessMessage = () => {
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000); // 3000 milliseconds (3 seconds)
  };

const handleSubmit = (e) => {
  e.preventDefault();

  const usertoken = localStorage.getItem('token');
  const formDataWithImages = new FormData();
  
  for (const key in formData) {
    if (key === 'avatar' || key === 'cover_img') {
      if (formData[key] instanceof File) {
        formDataWithImages.append(key, formData[key]);
      }
    } else {
      formDataWithImages.append(key, formData[key]);
    }
  }

  fetch(`https://dayoffice24.it/backend/api/profile/update`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${usertoken}`,
    },
    body: formDataWithImages, // Use the FormData object containing images
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('Server response:', data);

      if (data.success) {
        setSuccessMessage('Profile updated successfully');
        hideSuccessMessage(); 
      } else {
        console.error('Aggiornamento del profilo non riuscito:', data.message);
        setErrorMessage('Impossibile aggiornare il profilo. Per favore riprova.');
      }
    })
    .catch((error) => {
      console.error('Error updating profile:', error);
      setErrorMessage('Si è verificato un errore durante laggiornamento del profilo.');
    });
};


 const usertoken = localStorage.getItem('token');
  if (!usertoken) {
    return <Navigate to="/Login" />;
  }

  return (
    <div className="main_wrap">
      <Header />

      <div className="content-wrapper">
        <section class="section_padding pb-75 pt-100">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="tou_booking_form_Wrapper">
                  <div class="booking_tour_form">
                    <h3 class="heading_themed text-center mb-30">
                      Modifica i dettagli del tuo profilo
                    </h3>
                    <div class="tour_booking_form_box">
                      <form enctype="multipart/form-data" onSubmit={handleSubmit} id="tour_bookking_form_item">
                        {/* Personal Details */}
                        <div className="row">
                          <div className="col-sm-12 col-12">
                            <h6>Dati personali</h6>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label">
                                Nome<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control alpha"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label">
                                Immagine dell'utente 
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="avatar"
                                name="avatar"
                                accept="image/*"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="email">
                                E-mail<span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control emailInput"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="mobile_no">
                              Cellulare n.<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control number"
                                id="mobile_no"
                                minLength={10}
                                maxLength={10}
                                name="mobile_no"
                                value={formData.mobile_no}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label
                                className="form-label"
                                htmlFor="mobile_no"
                              >
                                Data di nascita
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control noFutureDate"
                                id="dob"
                                name="dob"
                                value={formData.dob}
                                onChange={handleChange}
                                required
                                max="2023-07-25"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label">
                               Immagine di copertina
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="cover_img"
                                name="cover_img"
                                accept="image/*"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-12">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="status">
                                Genere <span className="text-danger">*</span>
                              </label>
                              <br />
                              <input
                                type="radio"
                                value="male"
                                name="gender"
                                checked={formData.gender === 'male'}
                                onChange={handleChange}
                                required
                              />
                              <label htmlFor="male"> Maschio</label>
                              <input
                                type="radio"
                                value="female"
                                name="gender"
                                checked={formData.gender === 'female'}
                                onChange={handleChange}
                              />
                              <label htmlFor="female"> Femmina</label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-12">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="address">
                                Indirizzo<span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        {/* Company Details */}
                        <div className="col-sm-12 col-12">
                          <h6>Dettagli Personali</h6>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="company_name">
                                Nome e Cognome / Nome Azienda
                              </label>
                              <input
                                type="text"
                                className="form-control alpha"
                                id="company_name"
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleChange}
                                // required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label
                                className="form-label"
                                htmlFor="company_contact_no"
                              >
                               Telefono
                                {/*<span className="text-danger">*</span>*/}
                              </label>
                              <input
                                type="text"
                                className="form-control number"
                                id="company_contact_no"
                                name="company_contact_no"
                                minLength={10}
                                maxLength={10}
                                value={formData.company_contact_no}
                                onChange={handleChange}
                                // required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="gst_no">
                              C.F. {/*<span className="text-danger">*</span>*/}
                              </label>
                              <input
                                type="text"
                                className="form-control alphaNum"
                                id="gst_no"
                                name="gst_no"
                                minLength={15}
                                maxLength={15}
                                value={formData.gst_no}
                                onChange={handleChange}
                                // required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="vat_no">
                               Partita IVA.{/*<span className="text-danger">*</span>*/}
                              </label>
                              <input
                                type="text"
                                className="form-control alphaNum"
                                id="vat_no"
                                name="vat_no"
                                minLength={13}
                                maxLength={13}
                                value={formData.vat_no}
                                onChange={handleChange}
                                // required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="sdi">
                                SDI{/*<span className="text-danger">*</span>*/}
                              </label>
                              <input
                                type="text"
                                className="form-control alphaNum"
                                id="sdi"
                                name="sdi"
                                value={formData.sdi}
                                onChange={handleChange}
                                // required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label" htmlFor="pec">
                                PEC{/*<span className="text-danger">*</span>*/}
                              </label>
                              <input
                                type="text"
                                className="form-control alphaNum"
                                id="pec"
                                name="pec"
                                // required
                                value={formData.pec}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-12">
                          <div className="form-group mb-2">
                            <label
                              className="form-label"
                              htmlFor="company_address"
                            >
                              Indirizzo{/*<span className="text-danger">*</span>*/}
                            </label>
                            <textarea
                              className="form-control"
                              id="company_address"
                              name="company_address"
                              value={formData.company_address}
                              onChange={handleChange}
                              // required
                            />
                          </div>
                        </div>
                        {/* Bank Details */} 
                        <div className="col-sm-12 col-12">
                          <h6>Coordinate bancarie</h6>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label">
                                Nome del titolare
                              </label>
                              <input
                                type="text"
                                className="form-control alpha"
                                id="account_holder_name"
                                name="account_holder_name"
                                value={formData.account_holder_name}
                                onChange={handleChange}                                
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6"> 
                            <div className="form-group mb-2">
                              <label className="form-label">
                                Nome della banca
                              </label>
                              <input
                                type="text"
                                className="form-control alpha"
                                id="bank_name"
                                name="bank_name"
                                value={formData.bank_name}
                                onChange={handleChange}
                               />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label">
                                IBAN
                              </label>
                              <input
                                type="text"
                                className="form-control number"
                                id="bank_account_no"
                                name="bank_account_no"
                                value={formData.bank_account_no}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-6">
                            <div className="form-group mb-2">
                              <label className="form-label">
                                Codice IFSC
                              </label>
                              <input
                                type="text"
                                className="form-control alphaNum"
                                id="ifsc_code"
                                name="ifsc_code"
                                value={formData.ifsc_code}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-6">
                              <div className="form-group mb-2">
                                <label className="form-label">ID UPI</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="upi_id"
                                  name="upi_id"
                                  value={formData.upi_id}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-6">
                              <div className="form-group mb-2">
                                <label className="form-label">codice SWIFT</label>
                                <input
                                  type="text"
                                  className="form-control alphaNum"
                                  id="swift_code"
                                  name="swift_code"
                                  value={formData.swift_code}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-12">
                          <div className="form-group mb-2">
                            <label className="form-label" htmlFor="bank_address">
                              Indirizzo
                            </label>
                            <textarea
                              className="form-control"
                              id="bank_address"
                              name="bank_address"
                              value={formData.bank_address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <input type="hidden" name="uid" value={formData.uid} />
                        <div className="col-sm-12 col-12">
                        {successMessage && (
                          <div className="alert alert-success" role="alert">
                            {successMessage}
                          </div>
                        )}
                        {errorMessage && (
                          <div className="alert alert-danger" role="alert">
                            {errorMessage}
                          </div>
                        )}
                        <button type="submit" className="btn style1">
                          Aggiornamento
                        </button>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default EditProfile;
