import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialsCarousel = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    fetch('https://dayoffice24.it/backend/api/testimonial')
      .then((response) => response.json())
      .then((data) => {
        console.log('API Response:', data); // Add this line to check the API response
        if (data.success && data.data) {
          setTestimonials(data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching testimonial data:', error);
      });
  }, []);

  const settings = { 
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {testimonials.map((testimonial) => (
        <div key={testimonial._id}>
          <div className="testimonial-card style2">
            <h6>{testimonial.title}</h6>
            <p className="client-quote">{testimonial.short_desc}</p>
            <div className="client-info-wrap">
              <div className="client-img">
                <img src={testimonial.user_img} alt="Image" />
              </div>
              <div className="client-info">
                <h5>{testimonial.name}</h5>
                <span>{testimonial.position}</span>
              </div>
              <div className="ratings">
                <i className="flaticon-star"></i>
                {testimonial.star_rating}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialsCarousel;
