import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PaymentSuccess = () => {
  useEffect(() => {
    // Add a new entry to the browser's history without changing the URL
    window.history.pushState(null, '', '/PaymentSuccess');
  }, []);

  return (
    <div className="main_wrap">
      <Header />

      <div className="content-wrapper">
        <section className="section_padding pb-75 pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="tou_booking_form_Wrapper">
                  <div className="booking_tour_form">
                    <h3 className="heading_themed text-center mb-30">
                     Successo dell ordine
                    </h3>
                    <div className="tour_booking_form_box text-center">
                          <h6 className="green_success">Il tuo pagamento è stato ricevuto con successo.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default PaymentSuccess;
