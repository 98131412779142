import React, { useState, useEffect } from 'react';

const Accordion = () => {
  const [accordionData, setAccordionData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch('https://dayoffice24.it/backend/api/faq')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Log the API response to check its format
        if (data.success && data.data) {
          setAccordionData(data.data);
        } else {
          throw new Error('API returned an error: No FAQ data found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching FAQ data:', error);
      });
  }, []);

  const [activeAccordion, setActiveAccordion] = useState('');

  const toggleAccordion = (accordionId) => {
    setActiveAccordion(activeAccordion === accordionId ? '' : accordionId);
  };

  return (
    <div className="accordion" id="accordionExample"> 
      {accordionData.map((item) => (
        <div className="accordion-item" key={item._id}>
          <h2 className="accordion-header" id={`heading-${item._id}`}> 
            <button
              className={`accordion-button ${activeAccordion === item._id ? '' : 'collapsed'}`}
              type="button"
              onClick={() => toggleAccordion(item._id)}
            >
              <span>
                <i className="flaticon-plus plus"></i>
                <i className="flaticon-minus-sign minus"></i>
              </span>
              {item.question}
            </button>
          </h2>
          <div
            id={`collapse-${item._id}`}
            className={`accordion-collapse collapse ${activeAccordion === item._id ? 'show' : ''}`}
            aria-labelledby={`heading-${item._id}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="single-product-text">
                <p>{item.answer}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
