import React, { useState, useEffect } from 'react';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Axios from 'axios';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const usertoken = localStorage.getItem('token');

    // Fetch user profile data
    Axios.get('https://dayoffice24.it/backend/api/profile', {
      headers: {
        Authorization: `Bearer ${usertoken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.success) {
          setUserData(response.data.data);
        } else {
          console.error('Error fetching user data:', response.data.message); 
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error); 
      });
  }, []);

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('le passwords non corrispondono');
      return;
    }

    const usertoken = localStorage.getItem('token');

    // Send only new password and confirm password to the backend
    Axios.post(
      'https://dayoffice24.it/backend/api/profile/changePasswords',
      {
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${usertoken}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        if (response.data.success) {
          console.log('Password changed successfully!');
          setSuccessMessage('Password changed successfully!');

          // Clear success message after a few seconds
          setTimeout(() => {
            setSuccessMessage('');
            navigate('/Profile');
          }, 2000); // Change the delay as needed
        } else {
          console.error('Password change failed:', response.data.message);
          setErrorMessage('Impossibile modificare la password. Per favore riprova.');
        }
      })
      .catch((error) => {
        console.error('Error changing password:', error);
        setErrorMessage('Impossibile modificare la password. Per favore riprova.');
      });
  };

 const usertoken = localStorage.getItem('token');
  if (!usertoken) {
    return <Navigate to="/Login" />;
  }

  return (
    <div className="main_wrap">
      <Header userData={userData} setUserData={setUserData} />
      <div className="content-wrapper">
        <section className="property-details-wrap pt-75 pb-75">
          <div className="container">
            <div className="row align-items-center justify-content-center gx-5">
              <div className="col-md-6">
                <div className="login-content">
                  <h2>Cambia la tua password</h2>
                  <form className="user-form" onSubmit={handleChangePassword}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="new_password"
                            name="new_password"
                            type="password"
                            placeholder="Inserire la password"
                            required
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="confirm_password"
                            name="confirm_password"
                            type="password"
                            placeholder="Inserisci Conferma password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <button type="submit" className="btn style1">
                            Salva la password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
