import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import Axios from 'axios';
import { Helmet } from 'react-helmet';

const About = () => {
  const [aboutData, setAboutData] = useState(null);
  const [clientData, setClientData] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [short_desc, setShort_Desc] = useState("");

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await Axios.get('https://dayoffice24.it/backend/api/about-us');
        if (!response.data.error) {
          setAboutData(response.data.data);
          setError('');
        } else {
          setError('Failed to fetch data from the server.');
        }
      } catch (error) {
        setError('An error occurred while fetching data.');
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await fetch("https://dayoffice24.it/backend/api/section/5");

      const data = await response.json();
      setTitle(data.data.title);
      setId(data.data.id);
      setShort_Desc(data.data.short_desc);
      setClientData(data.data);
    } catch (error) {
      console.error("Error fetching about data:", error);
    }
  };


  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });

  useEffect(() => {
  fetch('https://dayoffice24.it/backend/api/seo-details/about')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching SEO data:', error);
    });
}, []);




  return (
    <div className="main_wrap">
     <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
     </Helmet>

      <Header />
      <div className="content-wrapper">
        <div className="breadcrumb-wrap bg-f br-2">
          <div className="container">
            <div className="breadcrumb-title text-center">
              <h2>Chi siamo</h2>
              <ul className="breadcrumb-menu list-style">
                <li><a href="index.html">Casa</a></li>
                <li>Chi siamo</li>
              </ul>
            </div>
          </div>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          aboutData && (
            <div className="pb-100 pt-100">
              <div className="container">
                <div className="video-wrap style1 video-bg-1 bg-f ptb-100" style={{
                  // Inline CSS styles here
                  background: 'url(' + aboutData.photo + ') center center no-repeat',
                  backgroundSize: 'cover',
                  // Add any other inline styles you need
                }}
              >
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-md-8 order-lg-1 order-md-1 order-2">
                      <div className="video-content">
                        <div className="content-title style1">
                          <h2>{aboutData.title}</h2>
                          <p>{aboutData.short_desc}</p>
                        </div>
                        <a href="/ExploreProperty" className="btn style2">
                          Esplora la proprietà
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-4 order-lg-2 order-md-2 order-1"></div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

     {aboutData ? (    
      <div className="counter-area style4">
          <div className="container">
            <div className="counter-card-wrap style4 bg-white">
              <div className="counter-card">
                <span className="counter-icon">
                  <i className="flaticon-quality-control"></i>
                </span>
                <div className="counter-text">
                  <h2 className="counter-num">
                    <span className="odometer">{aboutData.year_of_exp}</span>
                    <span className="target">+</span>
                  </h2>
                  <p>Anni di esperienza</p>
                </div>
              </div>
              <div className="counter-card">
                <span className="counter-icon">
                  <i className="flaticon-apartment"></i>
                </span>
                <div className="counter-text">
                  <h2 className="counter-num">
                    <span className="odometer">
                      {aboutData.total_rented_property}
                    </span>
                    <span className="target">+</span>
                  </h2>
                  <p>Appartamenti in affitto</p>
                </div>
              </div>
              <div className="counter-card">
                <span className="counter-icon">
                  <i className="flaticon-office-building"></i>
                </span>
                <div className="counter-text">
                  <h2 className="counter-num">
                    <span className="odometer">{aboutData.happy_customer}</span>
                    <span className="target">+</span>
                  </h2>
                  <p>Clienti felici</p>
                </div>
              </div>
              <div className="counter-card">
                <span className="counter-icon">
                  <i className="flaticon-hand-gesture"></i>
                </span>
                <div className="counter-text">
                  <h2 className="counter-num">
                    <span className="odometer">
                      {aboutData.total_listed_property}
                    </span>
                    <span className="target">+</span>
                  </h2>
                  <p>Camere Appartamento</p>
                </div>
              </div>
            </div>
          </div>
        </div>
         ) : null}

       </div>

         <section className="testimonial-wrap style2 ptb-100 bg-seashell">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                     <div className="section-title style1 text-center mb-40">
                        <h2>{title}</h2>
                        <p>{short_desc}</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container-fluid">
               <div className="testimonial-slider-two owl-carousel">
                  <TestimonialsCarousel />
               </div>
            </div>
         </section>



     <Footer />  

    </div>

  );
};

export default About;
