import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const CallAction = () => {
  const location = useLocation();
  const [sectionDatacall, setSectionDatacall] = useState(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');

  useEffect(() => {
    // Fetch data from the API
    fetch('https://dayoffice24.it/backend/api/section/6')
      .then((response) => response.json())
      .then((data) => {
        if (data.success && data.data) {
          setBackgroundImageUrl(data.data.photo);
          setSectionDatacall(data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching section data:', error);
      });
  }, []);

  return (
    <div className="add-listing-wrap">
      <div className="container-fluid px-0">
        <div className="row gx-0">
          <div className="col-md-6">
            <div className="add-listing-bg bg-f" style={{ backgroundImage: `url(${backgroundImageUrl})` }}></div>
          </div>
          <div className="col-md-6">
            <div className="listing-content ptb-100">
              {sectionDatacall && (
                <>
                  <div className="content-title">
                    <h2>{sectionDatacall.title}</h2>
                    <p>{sectionDatacall.short_desc}</p>
                  </div>
                  {location.pathname === '/HelpCenter' ? (
                    <a href="mailto:info@dayoffice24.it" className="btn style4">
                      Contattaci!
                    </a>
                  ) : (
                    <Link to="/HelpCenter" className="btn style4">
                      Scopri di +
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallAction;
