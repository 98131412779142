import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Axios from 'axios';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const handleResetPassword = async (event) => {
    event.preventDefault();

    // Extract the token from the URL query params
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token'); 

    // Validate new password and password confirmation
    if (!newPassword || !confirmPassword) {
      setError('Please enter both new password and confirm password.');
      setSuccessMessage('');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password must match.');
      setSuccessMessage('');
      return;
    }

    try {
    const response = await Axios.post(`https://dayoffice24.it/backend/api/reset-passwords/?token=${token}`, {
      password: newPassword,
      password_confirmation: confirmPassword,
    });

if (response.data.success) {
        setError('');
        setSuccessMessage('Password reset successful. You can now log in with your new password.');

        // Clear the form fields after successful password reset
        setNewPassword('');
        setConfirmPassword('');

        // Redirect to the login page after a successful password reset
        setTimeout(() => {
          navigate('/Login');
        }, 2000); // 2-second delay before redirecting to login page
      } else {
        setError(response.data.message);
        setSuccessMessage('');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      setSuccessMessage('');
    }
  };


return (
    <div className="main_wrap">

      <Header />
      <div className="content-wrapper">
        <section className="property-details-wrap pt-75 pb-75">
          <div className="container">
            <div className="row align-items-center justify-content-center gx-5">
              <div className="col-md-6">
                <div className="login-content">
                  <h2>Resetta la password</h2>
                  <form className="user-form" onSubmit={handleResetPassword} action="#">
                    <input type="hidden" name="token" value={new URLSearchParams(location.search).get('token')} />

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="password"
                            placeholder="nuova password"
                            required=""
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="password"
                            placeholder="Conferma password"
                            required=""
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button className="btn style1">Resetta la password</button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {error && <p className="text-danger">{error}</p>}
                  {successMessage && <p className="text-success">{successMessage}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
