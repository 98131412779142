import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductCarousel = ({ propertyData }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <Slider {...settings}>
        {propertyData.map((property) => (
          <div key={property._id}>
            <div className="property-card style4" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
              <div className="property-img">
                <Link to={`/PropertyView/${property._id}`}><img src={property.thumb_image} alt="Image" /></Link>
                <span className="property-status">{property.property_type}</span>
              </div>
              <div className="property-info">
                <h3><Link to={`/PropertyView/${property._id}`}>{property.property_name}</Link></h3>
                <p>{property.availableDates.view_date}</p>
                <p><i className="flaticon-location"></i>{property.address}</p>
                <ul className="property-metainfo list-style">
                  <li><i className="flaticon-user-avatar"></i>{property.no_of_guest}</li>
                  <li><i className="flaticon-square"></i>{property.property_size} sqft</li>
                </ul>
                <p className="property-price">€{property.price}<span>/ {property.price_charge_type}</span></p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductCarousel;
