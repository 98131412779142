import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios'; 
import { Helmet } from 'react-helmet';

const ExploreProperty = () => {

  const [propertyData, setPropertyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch the property data from the API
    axios.get('https://dayoffice24.it/backend/api/explore-property')
      .then((response) => {
        setPropertyData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching property data:', error);
        setIsLoading(false);
      });
  }, []);


const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });

  useEffect(() => {
  fetch('https://dayoffice24.it/backend/api/seo-details/exploreProperty')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching SEO data:', error);
    });
}, []);



  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="main_wrap">

      <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
     </Helmet>

      <Header />
          <div className="content-wrapper">
              <div className="breadcrumb-wrap bg-f br-2"> 
                 <div className="container">
                    <div className="breadcrumb-title text-center">
                       <h2>Esplora proprietà</h2>
                       <ul className="breadcrumb-menu list-style">
                        <li><Link to="/">Casa</Link></li>
                        <li>Esplora proprietà</li> 
                      </ul>
                    </div>
                 </div>
              </div>
              <div className="content-wrapper">
                <section className="property-details-wrap pt-100 pb-75">
                  <div className="container">              
                          <div className="row">                             
                             {Array.isArray(propertyData) && propertyData.map((property) => (
                              <div key={property.id} className="col-xl-4 col-lg-6 col-md-6">
                                <div className="property-card style4">
                                  <div className="property-img">
                                    <Link to={`/PropertyView/${property._id}`}><img src={property.thumb_image} alt={property.property_name} /></Link>
                                    <span className="property-status">{property.property_type}</span>
                                  </div>
                                  <div className="property-info">
                                    <h3><Link to={`/PropertyView/${property._id}`}>{property.property_name}</Link></h3>
                                     <p>{property.availableDates.view_date}</p>
                                    <p><i className="flaticon-location"></i>{property.address}</p>
                                    <ul className="property-metainfo list-style">
                                      <li><i className="flaticon-user-avatar"></i>{property.no_of_guest}</li>
                                      <li><i className="flaticon-square"></i>{property.property_size} sqft</li>
                                    </ul>
                                    <p className="property-price">€{property.price}/<span>{property.price_charge_type}</span></p>
                                  </div>
                                </div>
                              </div>
                            ))}
                         </div>  
                  </div>
                </section>
              </div>
          </div>
      <Footer />
    </div>
  );
};

export default ExploreProperty;
