import React, { useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const RedirectURL = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getTokenFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('token');
  };

  const token = getTokenFromUrl();

  // Save the token to local storage when the component mounts
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      navigate("/Profile"); 
    }
  }, [token]);

  return (
    <div className="main_wrap">
      <Header />

      <div className="content-wrapper">
        <section className="terms-wrap pt-100 pb-75 mb-10">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-12">
                  
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default RedirectURL;
