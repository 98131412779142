import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Accordion from '../components/Accordion';
import { Helmet } from 'react-helmet';

const Faq = () => {


  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });

  useEffect(() => {
  fetch('https://dayoffice24.it/backend/api/seo-details/faq')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching SEO data:', error);
    });
}, []);



  return (
     
   <div className="main_wrap">

     <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
     </Helmet>   

     <Header />

     <div className="content-wrapper"> 
      
         <div className="breadcrumb-wrap bg-f br-2"> 
            <div className="container">
               <div className="breadcrumb-title text-center">
                  <h2>Domande frequenti</h2>
                  <ul className="breadcrumb-menu list-style">
                     <li> <Link to="/">Casa</Link></li>
                     <li>FAQ</li>
                  </ul>
               </div>
            </div>
         </div> 

         <section className="faq-wrap style4  ptb-100">
            <div className="container">
               <div className="row gx-5 justify-content-center">
                  <div className="col-lg-8">
                     <div className="faq-content">
                        <div className="content-title style1 text-center">
                           <span>Hai domande?</span>
                        </div>
                        
                         <Accordion />
                        
                     </div>
                  </div>
               </div>
            </div>
         </section>

     </div>

     <Footer />  

    </div>

  );
};

export default Faq;
