import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallAction from "../components/CallAction";
const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [msgCount, setMsgCount] = useState(null);
  useEffect(() => {
    const usertoken = localStorage.getItem("token");
    if (usertoken) {
      fetch("https://dayoffice24.it/backend/api/profile", {
        redirect: "manual",
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      })
      .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 302) {
            // Handle the redirection
            const newUrl = response.headers.get("Location");
            if (newUrl) {
              return fetch(newUrl); // Follow the redirection manually
            } else {
              throw new Error("Redirection URL not provided.");
            }
          } else {
            throw new Error("Network response was not ok");
          }
        })
        .then((data) => {
          console.log(data);
          if (data.success) {
            setProfileData(data.data); // Set the profile data received from the API to the state
          } else {
            throw new Error("API returned an error: " + data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
      fetch("https://dayoffice24.it/backend/api/chat/total-msg-count", {
        redirect: "manual",
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Network response was not ok");
          }
        })
        .then((data) => {
          if (data.success) {
            setMsgCount(data.data);
          } else {
            throw new Error("API returned an error: " + data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    } else {
      // Redirect or show an error message since the user token is not available
    }
  }, []);
  const usertoken = localStorage.getItem("token");
  if (!usertoken) {
    return <Navigate to="/Login" />;
  }
  return (
    <div className="main_wrap">
      <Header />
      <div className="content-wrapper">
        <section className="profile_cover_image"> 
          {profileData && (
            <img src={profileData.cover_img} alt={profileData.name} />
          )}
        </section>
        <section className="profile_wrapper">
          <div className="container">
            <div className="row">
              {profileData && (
                <div className="col-md-3">
                  <div className="profile_box">
                    <div className="profile_pic">
                      <img src={profileData.avatar} alt={profileData.name} /> 
                    </div>
                    <div className="profile_content">
                      <h3>{profileData.name}</h3>
                      <div className="profie_data">
                        <p>
                          <i className="ri-phone-fill"></i>{" "}
                          {profileData.mobile_no}
                        </p>
                        <p>
                          <i className="ri-mail-fill"></i> {profileData.email}
                        </p>
                        <p> 
                          <i className="ri-map-pin-line"></i>{" "}
                          {profileData.address}
                        </p>
                      </div>
                    </div>
                    <div className="chat_btn">
                      <Link className="btn style1" to="/Chatboat">
                        <i className="ri-message-2-fill"></i> Chat &nbsp;
                        <span
                          className={
                            msgCount && msgCount.total_msg_count > 0
                              ? "badge rounded-pill badge-notification bg-danger"
                              : "badge rounded-pill badge-notification bg-danger d-none"
                          }
                          title="chat"
                        >
                          {msgCount ? msgCount.total_msg_count || 0 : 0}
                        </span>

                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-9">
                <div className="profile_about_section">
                  <h3>About {profileData && profileData.name}</h3>
                  <div className="profile_section">
                    <p>
                      <strong>Informazioni Personali</strong>
                    </p>
                    {profileData && (
                      <p>
                        <strong>Data di Nascita </strong>
                        {profileData.dob}
                      </p>
                    )}
                  </div>
                  <div className="profile_section">
                    <p>
                      <strong>Informazioni Azienda</strong>
                    </p>
                    {profileData && profileData.visitor && (
                      <div>
                        <p>
                          <strong>Nome Azienda: </strong>
                          {profileData.visitor.company_name}
                        </p>
                        <p>
                          <strong>Telefono:</strong>{" "}
                          {profileData.visitor.company_contact_no}
                        </p>
                        <p>
                          <strong>Indirizzo: </strong>
                          {profileData.visitor.company_address}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="profile_section">
                    <p>
                      <strong>Info Banca</strong>
                    </p>
                    {profileData && (
                      <div>
                        <p>
                          <strong>Nome Titolare </strong>{" "}
                          {profileData.account_holder_name}
                        </p>
                        <p>
                          <strong>Nome Banca </strong>
                          {profileData.bank_name}
                        </p>
                        <p>
                          <strong>Indirizzo Banca </strong>{" "}
                          {profileData.bank_address}
                        </p>
                        <p>
                          <strong>IFSC Code </strong>
                          {profileData.ifsc_code}
                        </p>
                        <p>
                          <strong>Swift Code </strong> {profileData.swift_code}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CallAction />
      </div>
      <Footer />
    </div>
  );
};
export default Profile;
