import React, { useState, useEffect } from 'react';
import { Navigate, Link } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';

const OrderHistory = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add this state

  useEffect(() => {
    const usertoken = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${usertoken}`,
      'Content-Type': 'application/json',
    };

    fetch('https://dayoffice24.it/backend/api/order/list', { headers })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('API request failed with status: ' + response.status);
        }
      })
      .then((data) => {
        console.log('API Response:', data);
        if (data.success) {
          setOrderHistory(data.data);
        } else {
          throw new Error('API returned an error:' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching order history:', error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false once the request is completed
      });
  }, []);


   const usertoken = localStorage.getItem('token');
  if (!usertoken) {
    return <Navigate to="/Login" />;
  }


  return (
    <div className="main_wrap">
      <Header />

      <div className="content-wrapper">
        <section className="section_padding pb-75 pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="tou_booking_form_Wrapper">
                  <div className="booking_tour_form">
                    <h3 className="heading_themed text-center mb-30">
                     Cronologia Ordini
                    </h3>
                    <div className="tour_booking_form_box">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : orderHistory.length > 0 ? (
                          <table className="table order_history_table">
                          <thead>
                            <tr>
                              <th>Location</th>
                              <th>Nome Struttura</th>
                              <th>Prenotato dal</th>
                              <th>Fino al</th>
                              <th>Importo</th>
                              <th>Stato</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderHistory.map((order) => (
                              <tr key={order._id}>
                                <td>
                                  {order.property &&
                                  order.property.thumb_image ? (
                                    <img
                                      className="property_img_order"
                                      src={order.property.thumb_image}
                                      alt={order.property.property_name}
                                    />
                                  ) : (
                                    <span>No image available</span>
                                  )}
                                </td>
                                <td>
                                  {order.property
                                    ? order.property.property_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {new Date(
                                    order.start_date
                                  ).toLocaleDateString("en-GB")}
                                </td>
                                <td>
                                  {new Date(order.end_date).toLocaleDateString(
                                    "en-GB"
                                  )}
                                </td>
                                <td>€{order.payable_amount}</td>
                                <td className="table_btn">
                                  {order.status === "booked" ? (
                                    <p>Prenotato</p>
                                  ) : (
                                    <Link
                                      className="btn style1 btn-primary p-2"
                                      to={`/Payment?bookingId=${order._id}`}
                                    >
                                      Paga Ora
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                    ) : (
                      <p className="no_order">Nessuna cronologia degli ordini trovata</p>
                    )}
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default OrderHistory;
