import React, { useState, useEffect } from 'react';
import { useNavigate, Link, Navigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [seoDetails, setSeoDetails] = useState({ title: '', description: '', keywords: '' });


  useEffect(() => {
    fetchSeoDetails();
  }, []);

  const fetchSeoDetails = async () => {
    try {
      const response = await fetch('https://dayoffice24.it/backend/api/seo-details/login');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setSeoDetails({
          title: data.data.page_title,
          description: data.data.meta_desc,
          keywords: data.data.keywords,
        });
      } else {
        throw new Error('API returned an error: ' + data.message);
      }
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  };


const handleLogin = async (event) => {
  event.preventDefault();

  // Validate email and password fields
  if (!email || !password) {
    setError('Inserisci sia l e-mail che la password.');
    return;
  }

  // Validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setError('Si prega di inserire un indirizzo email valido.');
    return;
  }

    try {
      const response = await Axios.post('https://dayoffice24.it/backend/api/login', {
        email: email,
        password: password,
      });

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        navigate("/Profile"); // Redirect to profile or dashboard
      } else {
        if (response.data.error === true) {
          setError(response.data.message);
        } else {
          setError("Accesso non riuscito. Controlla le tue credenziali.");
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Si è verificato un errore. Per favore riprova più tardi.');
      }
    }
  };



  // Function to handle social login
  const handleSocialLogin = (providerUrl) => {
    // Open a new window or redirect the user to the social provider's login page
    window.location.href = providerUrl;
  };

  // Function to extract and handle the token from the URL
  const handleTokenExtraction = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');

    if (token) {
      // Save the token in local storage
      localStorage.setItem('token', token);
      // Redirect to the user's profile page
      navigate('/Profile');
    }
  };


  // Assuming the user has successfully logged in, handle the redirection
const handleLoginSuccess = () => {
  const returnUrl = new URLSearchParams(location.search).get('returnUrl');
  if (returnUrl) {
    // Redirect to the URL stored in returnUrl
    navigate(returnUrl);
  } else {
    // Redirect to the default page (e.g., Home or Profile)
    navigate('/');
  }
};





  // Call the function to handle token extraction on component mount
  useEffect(() => {
    handleTokenExtraction();
  }, []);

  return (
    <div className="main_wrap">

     <Helmet>
      <title>{seoDetails.title} - HRD Group Srl S.</title>
      <meta name="description" content={seoDetails.description} />
      <meta name="keywords" content={seoDetails.keywords} />
     </Helmet>

      <Header />
      <div className="content-wrapper">
        <section className="property-details-wrap pt-75 pb-75"> 
          <div className="container">
            <div className="row align-items-center login_bg gx-5">
              <div className="col-md-6">
                <div className="login-bg bg-f"></div>
              </div>
              <div className="col-md-6">
                <div className="login-content">
                  <h2>Registrazione</h2>
                  <p>Non hai un account? <a href="/Register"> Clicca qui </a> per registrarti ora!</p>
                  <form className="user-form" onSubmit={handleLogin} action="#">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group"> 
                          <input 
                            id="text"
                            name="email"
                            type="text"
                            placeholder="E-mail"
                            required=""
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          /> 
                        </div>
                      </div>
                      <div className="col-lg-12"> 
                        <div className="form-group">
                          <input
                            id="pwd"
                            name="pwd"
                            type="password"
                            placeholder="Password"
                            required=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12"> 
                        <div className="form-group">
                          <button className="btn style1">
                            Login
                          </button>
                        </div>
                      </div>
                      {error && (
                        <div className="col-lg-12">
                          <p className="text-danger">{error}</p>
                        </div>
                      )}
                      <Link to="/ForgotPassword">Ha dimenticato la password?</Link>
                      <div className="col-12">
                        <p className="text-center mb-10">Oppure</p>
                      </div>
                      <div className="col-12 text-center">
                        <p className="mb-0">Accedi con il tuo account sui social media</p>
                      </div>
                      <div className="col-12">
                        <div className="social-icon social_icon_login">
                          <Link className="social" target="_blank" to="https://dayoffice24.it/backend/auth/google/redirect/user"><i className="ri-google-fill"></i></Link>
                          <Link className="social" target="_blank" to="https://dayoffice24.it/backend/auth/twitter/redirect/user"><i className="ri-twitter-fill"></i></Link>
                          <Link className="social" target="_blank"  to="https://dayoffice24.it/backend/auth/linkedin/redirect/user"><i class="ri-linkedin-fill"></i></Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />  
    </div>
  );
};

export default Login;
